<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <v-tabs
          v-if="dashboardUser"
          v-model="selectedTab"
        >
          <v-tab
            v-for="item in items"
            :key="item.name"
            :disabled="item.is_active == 0 || !dashboardUser.dashboard_ids.includes(item.id)"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === glassPurchseOrderIndex">
      <v-col class="pb-0">
        <div
          id="2dd6b8ba-ad4c-4105-8f59-01e5f7faf129"
          style="height: 87vh;"
        />
      </v-col>
    </v-row>
    <v-row v-show="selectedTab === chrisleaInventoryIndex">
      <v-col class="pb-0">
        <div
          id="6c382cb1-d915-4b35-8997-b705dd105e6e"
          style="height: 87vh;"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as pbi from 'powerbi-client';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    selectedTab: null,
    items: [],
    dashboardUser: null,
    glassPurchseOrderIndex: 0,
    glassPurchaseOrderId: '2dd6b8ba-ad4c-4105-8f59-01e5f7faf129',
    chrisleaInventoryIndex: 1,
    chrisleaInventoryId: '6c382cb1-d915-4b35-8997-b705dd105e6e',
  }),

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters([

    ]),
  },
  mounted() {
    this.getEmbedReports();
  },

  created() {
    this.init();
  },
  methods: {
    init() {
      this.getUsers();
    },

    async get() {
      this.items = await this.$api.dashboards.get();
      // eslint-disable-next-line no-return-assign, no-param-reassign
      this.items.forEach((item, index) => item.index = index);

      const firstItem = this.items.find((item) => item.is_active === 1 && this.dashboardUser.dashboard_ids.includes(item.id));
      if (firstItem) {
        this.selectedTab = firstItem.index;
      }
    },

    async getUsers() {
      const submitModel = {
        filters: {
          user_id: this.user.id,
        },
      };
      const res = await this.$api.dashboards.read(submitModel, null, 'read/users');
      if (!res) return;
      const firstIndex = 0;
      this.dashboardUser = res[firstIndex];
      this.get();
    },
    async getEmbedReports() {
      const res = await this.$api.dashboards.get('auth');
      if (!res) return;
      const reports = res.filter((item) => item.report_id === this.glassPurchaseOrderId || item.report_id === this.chrisleaInventoryId);
      const { models } = pbi;
      reports.forEach((item) => {
        const config = {
          type: 'report',
          tokenType: models.TokenType.Embed,
          accessToken: item.embed_token,
          embedUrl: item.embed_url,
          id: item.report_id,
          settings: {
            panes: {
              filters: {
                visible: false,
              },
              pageNavigation: {
                visible: false,
              },
            },
            bars: {
              statusBar: {
                visible: false,
              },
            },
          },
        };
        if (item.report_id === this.glassPurchaseOrderId) {
          config.settings.zoomLevel = 1.25;
        }
        const reportContainer = document.getElementById(`${item.report_id}`);
        if (reportContainer) {
          // eslint-disable-next-line no-undef
          powerbi.embed(reportContainer, config);
        }
      });
    },
  },
};
</script>

<style scoped>
::v-deep iframe {
        border: none !important;
    }
</style>
