<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      fullscreen
    >
      <v-card tile>
        <v-card-title>
          Physical Count "{{ physicalCountList ? physicalCountList.name : '' }}, as of {{ physicalCountList ? physicalCountList.date : '' }}"

          <v-col class="text-right">
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="3"
              class="mb-3"
            >
              <v-text-field
                v-model="search"
                append-icon="fas fa-search"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
            <v-col
              cols="2"
              class="mb-3"
            >
              <inventory-class-select-element
                v-model="inventoryClassId"
                :is-add-all="false"
                :is-state="true"
              />
            </v-col>
            <v-col
              cols="3"
              class="mb-3"
            >
              <v-autocomplete
                v-model="location"
                label="Location"
                :items="locations"
                item-text="name"
                item-value="id"
                :clearable="true"
              />
            </v-col>
          </v-row>
          <v-data-table
            class="elevation-1"
            :headers="profilesHeaders"
            :items="physicalCounts"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :loading="loading"
            :search="search"
            :height="windowHeight"
            fixed-header
            dense
            :server-items-length="totalRecords"
            @update:options="updatePagination"
          >
            <template #[`item.inventory_tag`]="{ item }">
              <text-highlight
                class="font-weight-bold"
                :queries="search"
              >
                {{ item ? item.inventory_tag : '' }}
              </text-highlight>
            </template>
            <template #[`item.item_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item ? item.item_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.length`]="{ item }">
              {{ item && item.length ? item.length.name : '' }}
            </template>
            <template #[`item.uom`]="{ item }">
              {{ item && item.uom ? item.uom.name : '' }}
            </template>
            <template #[`item.location`]="{ item }">
              {{ item && item.location ? item.location.name : '' }}
            </template>
            <template #[`item.color_code`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item ? item.color_code : '' }}
              </text-highlight>
            </template>
            <template #[`item.is_fritted`]="{ item }">
              {{ item.is_fritted === null ? '-' : item.is_fritted ? 'Fritted' : 'Non-Fritted' }}
            </template>
            <template #[`item.inventory_location`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item && item.inventory_location ? item.inventory_location.name : '' }}
              </text-highlight>
            </template>
            <template #[`item.quantity`]="{ item }">
              {{ item ? item.quantity : '-' }}
            </template>
            <template #[`item.created_at`]="{ item }">
              {{ item ? item.created_at : '' }}
            </template>
            <template #[`item.created_by`]="{ item }">
              {{ item ? item.user_full_name : '' }}
            </template>
          </v-data-table>
          <v-row>
            <v-col cols="8" />
            <v-col
              class="text-end mt-2"
              cols="2"
            >
              <h3
                :style="{color: isDarkMode ? '' : 'black'}"
                class="font-weight-bold"
              >
                Total Records: {{ totalRecords }}
              </h3>
            </v-col>
            <v-col
              class="text-end mt-2"
              cols="2"
            >
              <h3
                :style="{color: isDarkMode ? '' : 'black'}"
                class="font-weight-bold"
              >
                Total Quantity: {{ totalQuantity }}
              </h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import TextHighlight from 'vue-text-highlight';

export default {
  components: {
    TextHighlight,
  },
  props:
  {
    physicalCountList: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    physicalCounts: [],
    locations: [],

    loading: false,
    dialog: false,
    search: '',
    location: null,
    totalRecords: 0,
    totalQuantity: 0,
    inventoryClassId: 1,

    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdPanels: 8,
    inventoryClassIdSID: 9,
    inventoryClassIdPanelParts: 10,
    inventoryClassIdPaint: 12,
    inventoryClassIdPatioDoor: 14,

    topSpaceHeight: 280,
    windowHeight: 1000,
    pagination: {
      itemsPerPage: 100,
      page: 1,
      sortBy: 'physical_counts.id',
      sortDesc: true,
    },
    footerProps: {
      'items-per-page-options': [100, 500, 1000, -1],
    },

  }),
  computed: {
    profilesHeaders() {
      const headers = [];
      // headers.push({ text: 'Class', value: 'inventory_class' });
      headers.push({ text: 'QR Code', value: 'inventory_tag', sortable: false });
      headers.push({ text: 'Inventory Item', value: 'item_name', sortable: false });

      if(this.inventoryClassId !== this.inventoryClassIdSID && this.inventoryClassId !== this.inventoryClassIdGlass && this.inventoryClassId !== this.inventoryClassIdVWDGlass && this.inventoryClassId !== this.inventoryClassIdPanels && this.inventoryClassId !== this.inventoryClassIdPatioDoor) {
        headers.push({ text: 'Length', value: 'length', sortable: false });
      }

      headers.push({ text: 'UOM', value: 'uom', sortable: false });
      
      if(this.inventoryClassId !== this.inventoryClassIdSID && this.inventoryClassId !== this.inventoryClassIdGlass && this.inventoryClassId !== this.inventoryClassIdVWDGlass){
        headers.push({ text: this.inventoryClassId === this.inventoryClassIdPatioDoor ? 'Interior Color' : 'Color', value: 'color_code', sortable: false });
      }

      if (this.inventoryClassId === this.inventoryClassIdPatioDoor) {
        headers.push({
          text: 'Exterior Color', value: 'ex_color_code', filterable: false, sortable: false, width: '15%',
        });
        headers.push({
          text: 'Door Height', value: 'height', filterable: false, sortable: false,
        });
        headers.push({
          text: 'Door Width', value: 'width', filterable: false, sortable: false,
        });
        headers.push({
          text: 'Fritted', value: 'is_fritted', filterable: false, sortable: false,
        });
        headers.push({
          text: 'Door Screen', value: 'door_screen.name', filterable: false, sortable: false,
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Size', value: 'door_size.name', filterable: false, sortable: false,
        });
        headers.push({
          text: 'Hinge', value: 'door_hinge.name', filterable: false, sortable: false,
        });
        headers.push({
          text: 'Swing', value: 'door_swing.name', filterable: false, sortable: false,
        });
        headers.push({
          text: 'Lite', value: 'door_lite.name', filterable: false, sortable: false,
        });
        headers.push({
          text: 'In Color', value: 'color_code', filterable: false, sortable: false,
        });
        headers.push({
          text: 'Ex Color', value: 'ex_color_code', filterable: false, sortable: false,
        });
      }

      headers.push({ text: 'Location', value: 'location', sortable: false });
      headers.push({ text: 'Shelf', value: 'inventory_location', sortable: false});
      headers.push({ text: 'Quantity', value: 'quantity', sortable: true});
      headers.push({ text: 'Created At', value: 'created_at', sortable: false });
      headers.push({ text: 'Created By', value: 'created_by', sortable: false });

      return headers;
    },
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
  },

  watch: {
    pagination() {
      this.getPhysicalCounts();
    }, 
    search() {
      this.getPhysicalCounts();
    },
    physicalCountList() {
      this.getPhysicalCounts();
    },
    inventoryClassId() {
      this.getPhysicalCounts();
    },
    location() {
      this.getPhysicalCounts();
    }
  },

  created() {
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.getLocations();
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    open() {
      this.dialog = true;
    },

    async getPhysicalCounts() {
      if (!this.physicalCountList) return;
      this.loading = true;
      this.physicalCounts = [];
      this.totalRecords = 0;
      this.totalQuantity = 0;

      const submitModel = {
        filters: {
          physical_count_list_id: this.physicalCountList.id,
          inventory_class_id: this.inventoryClassId,
          location_id: this.location,
        },
        pagination: this.pagination,
        search: this.search
      }

      const res = await this.$api.physicalCount.read(submitModel);
      this.physicalCounts = res.data;
      this.totalRecords = res.total;
      this.physicalCounts.forEach((x) => { this.totalQuantity += x.quantity; });
      this.loading = false;
    },

    async getLocations() {
      const res = await this.$api.location.get();

      if (!res) return;
      this.locations = res;
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updatePagination(newOptions) {
      if (newOptions.page !== this.pagination.page || newOptions.itemsPerPage !== this.pagination.itemsPerPage || newOptions.sortBy !== this.pagination.sortBy || newOptions.sortDesc !== this.pagination.sortDesc) {
        this.pagination = newOptions;
      }
    }
  },

};
</script>
