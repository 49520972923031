<template>
  <v-container fluid>
    <v-row>
      <v-col class="pt-5">
        <h3>Extra Item Types</h3>
      </v-col>
      <v-col class="text-end">
        <v-btn
          color="primary"
          outlined
          @click="openExtraTypeForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Extra Item
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="extraItemTypes"
            :footer-props="footerProps"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.id`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.id }}
              </text-highlight>
            </template>
            <template #[`item.name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.name }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEditExtraItemTypeForm(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteExtraItemType(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <bill-of-lading-extra-item-type-form
      ref="extraItemTypeForm"
      @success="getExtraItemTypes"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BillOfLadingExtraItemTypeForm from '@/components/forms/billOfLadings/BillOfLadingExtraItemTypeForm.vue';

export default {
  components: {
    BillOfLadingExtraItemTypeForm
  },
  props: {

  },
  data: () => ({
    extraItemTypes: [],
    loading: false,
    headers: [
      { text: 'Type#', value: 'id' },
      { text: 'Name', value: 'name' },
      {
        text: 'Actions', value: 'actions', width: '15%', align: 'end',
      },
    ],
    footerProps: {
      'items-per-page-options': [20, 50, 100, -1],
    },
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getExtraItemTypes();
    },

    async getExtraItemTypes() {
      this.extraItemTypes = await this.$api.billOfLadingExtraItemType.get();
    },

    openExtraTypeForm() {
      this.$refs.extraItemTypeForm.open();
    },

    openEditExtraItemTypeForm(id) {
      this.$refs.extraItemTypeForm.open(id);
    },

    async deleteExtraItemType(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.billOfLadingExtraItemType.delete(id);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.getExtraItemTypes();
    },
  },
};
</script>

<style scoped>

</style>
