var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-5",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Status","items":_vm.statuses,"item-text":"name","item-value":"id","hide-details":"true","loading":_vm.statusLoading,"clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1),_c('v-col',{staticClass:"pb-5",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"label":"Job","items":_vm.jobs,"hide-details":"true","item-text":function (item) { return ((item.code) + " - " + (item.name) + "; " + (item.reference)); },"item-value":"id","loading":_vm.jobLoading,"clearable":"","single-select":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.sub_class)?_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.sub_class ? item.sub_class.color : 'white'}},[_vm._v(" "+_vm._s(item && item.sub_class ? item.sub_class.name : '')+" ")]):_vm._e(),_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.sub_class)?_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.sub_class ? item.sub_class.color : 'white'}},[_vm._v(" "+_vm._s(item && item.sub_class ? item.sub_class.name : '')+" ")]):_vm._e(),_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}}]),model:{value:(_vm.filterJob),callback:function ($$v) {_vm.filterJob=$$v},expression:"filterJob"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Truck Type","items":_vm.truckTypes,"item-text":"name","item-value":"id","hide-details":"true","loading":_vm.truckTypeLoading,"clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.filterTruckType),callback:function ($$v) {_vm.filterTruckType=$$v},expression:"filterTruckType"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-autocomplete',{attrs:{"label":"Truck Number","items":_vm.trucks,"item-text":"truck_number","item-value":"id","hide-details":"true","loading":_vm.truckLoading,"clearable":""},model:{value:(_vm.filterTruck),callback:function ($$v) {_vm.filterTruck=$$v},expression:"filterTruck"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"mt-4 pb-0",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"color":"success","outlined":"","disabled":_vm.selected.length <= 0},on:{"click":_vm.runWebhookAll}},[_vm._v(" PDF ")])],1)]}}])},[_c('span',[_vm._v("Select At Least One Bill Of Lading")])])],1),_c('v-col',{staticClass:"mt-4 pb-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openNewBillOfLading}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Bill Of Lading ")],1)],1),_c('v-col',{staticClass:"mt-4 pb-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.openReturnWindow}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" Return Item ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":_vm.pagination.itemsPerPage,"search":_vm.search,"loading":_vm.loading,"show-select":"","dense":"","fixed-header":"","height":_vm.windowHeight,"server-items-length":_vm.total},on:{"update:options":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.id ? item.id : '-')+" ")])]}},{key:"item.job.code",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_code ? ("" + (item.job_code)) : ''))])],1),_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" - "+_vm._s(item.job_name ? item.job_name : ''))]),_vm._v(" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(item.job_reference ? ("; " + (item.job_reference)) : ''))])],1)],1)]}},{key:"item.status.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.status ? item.status.color : '',"text-color":"white"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.status ? item.status.name : '')+" ")])],1)]}},{key:"item.truck.name",fn:function(ref){
var item = ref.item;
return [(item.truck && item.truck.type && item.truck.type.color && item.truck.type.name)?_c('v-chip',{attrs:{"small":"","color":item.truck.type.color
                  ? item.truck.type.color
                  : 'grey',"text-color":"white"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.truck.type.name ? item.truck.type.name : '')+" ")])],1):_c('span',[_vm._v("-")])]}},{key:"item.truck.truck_number",fn:function(ref){
                  var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.truck && item.truck.truck_number ? item.truck.truck_number : '-')+" ")])]}},{key:"item.truck.plate_number",fn:function(ref){
                  var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.truck && item.truck.plate_number ? item.truck.plate_number : '-')+" ")])]}},{key:"item.total_item_quantity",fn:function(ref){
                  var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.total_item_qty ? item.total_item_qty : '-')+" ")])]}},{key:"item.total_weight",fn:function(ref){
                  var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item && item.total_weight ? ("" + (item.total_weight.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }))) : '-')+" ")])]}},{key:"item.updated_at",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('span',_vm._g({},on),[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.updated_at ? _vm.formatDateTime(item.updated_at) : '')+" ")])],1)]}}],null,true)},[_c('v-container',{staticStyle:{"min-width":"300px"}},[(item.shipped_at)?_c('v-row',[_c('v-col',{staticClass:"pb-1 pt-0"},[_c('span',{staticStyle:{"color":"white","font-size":"16px"}},[_vm._v("Shipped At: ")]),_c('span',{staticClass:"pl-5",staticStyle:{"color":"white","font-size":"16px"}},[_vm._v(_vm._s(_vm.formatDateTime(item.shipped_at)))])])],1):_vm._e(),(item.shipped_by_full_name)?_c('v-row',[_c('v-col',{staticClass:"pb-1 pt-0"},[_c('span',{staticStyle:{"color":"white","font-size":"16px"}},[_vm._v("Shipped By: ")]),_c('span',{staticClass:"pl-5",staticStyle:{"color":"white","font-size":"16px"}},[_vm._v(_vm._s(item.shipped_by_full_name))])])],1):_vm._e(),(item.received_at)?_c('v-row',[_c('v-col',{staticClass:"pb-1 pt-0"},[_c('span',{staticStyle:{"color":"white","font-size":"16px"}},[_vm._v("Received At: ")]),_c('span',{staticClass:"pl-5",staticStyle:{"color":"white","font-size":"16px"}},[_vm._v(_vm._s(_vm.formatDateTime(item.received_at)))])])],1):_vm._e(),(item.received_by_full_name)?_c('v-row',[_c('v-col',{staticClass:"pb-1 pt-0"},[_c('span',{staticStyle:{"color":"white","font-size":"16px"}},[_vm._v("Received By: ")]),_c('span',{staticClass:"pl-5",staticStyle:{"color":"white","font-size":"16px"}},[_vm._v(_vm._s(item.received_by_full_name))])])],1):_vm._e()],1)],1)]}},{key:"item.created_by",fn:function(ref){
                  var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.created_by_full_name ? item.created_by_full_name : '-')+" ")])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":item.status_id === _vm.billOfLadingStatusIdShipped
                  ? 'success'
                  : 'purple',"outlined":"","small":""},on:{"click":function($event){return _vm.toggleReceiveStatus(item)}}},[_vm._v(" "+_vm._s(item.status_id === _vm.billOfLadingStatusIdShipped ? 'RECEIVE' : 'UNDO RECEIVE')+" ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){item.status_id === _vm.billOfLadingStatusIdShipped ? _vm.openBillOfLading(item, true) : _vm.openBillOfLading(item)}}},[_vm._v(" "+_vm._s(item.status_id === _vm.billOfLadingStatusIdShipped ? 'EDIT' : 'OPEN')+" ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 mt-1 mb-1",staticStyle:{"border-radius":"7px","width":"28px !important","height":"28px !important"},attrs:{"elevation":"0","fab":"","outlined":"","x-small":"","tile":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-3"},[_c('v-list-item',{on:{"click":function($event){return _vm.runWebhook(item)}}},[_c('v-list-item-title',{style:("color: green; font-weight: 600;")},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"auto"}},[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"green"}},[_vm._v(" fas fa-file ")])],1),_c('v-col',{staticClass:"pt-4"},[_vm._v(" PDF ")])],1)],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.softDelete(item)}}},[_c('v-list-item-title',{style:("color: red; font-weight: 600;")},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"pt-4"},[_vm._v(" DELETE ")])],1)],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),_c('bill-of-lading-form',{ref:"billOfLadingForm",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }