/* eslint-disable no-unused-expressions */
<template>
  <v-container fluid>
    <v-container
      v-if="inventoryTag != null"
      style="font-size: 1.3em !important;"
      fluid
    >
      <v-row
        v-if="physicalCountList"
      >
        <v-col
          cols="auto"
          class="pb-0 pt-0"
        >
          <h3>
            Active Physical Count {{ `'${physicalCountList.name}'` }}, as of {{ new Date(`${physicalCountList.date} 00:00`).toDateString().substring(4) }}
          </h3>
        </v-col>
        <v-col class="text-end pb-2 pt-0">
          <v-btn
            color="orange"
            class="mr-4"
            outlined
            x-large
            @click="openNotes"
          >
            Notes
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4"
            x-large
            @click="historyRedirect"
          >
            History
          </v-btn>
          <v-btn
            v-if="!isEdit"
            color="primary"
            class="mr-4"
            outlined
            x-large
            @click="toggleEdit"
          >
            edit
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="isCounted"
          class="pt-0 pb-1 mt-0"
        >
          <h3
            class="green--text"
            style="font-size: 1.1em !important;"
          >
            This Inventory Tag has already been counted with Quantity of <span
              class="red--text"
              style="font-size: 1.3em !important;"
            >{{ inventoryTag.quantity }}</span>
          </h3>
        </v-col>
      </v-row>
      <v-row
        class="pa-0 mt-5"
      >
        <v-col
          class="pa-0"
          cols="1"
          md="1"
          sm="2"
          style="text-align:right"
        >
          <v-row>
            <v-col class="text-center">
              <h5 class="h5">
                QR Code
              </h5> <span style="font-size: 1.5em;"> {{ qrCode }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-chip
                class="mt-1 mb-1"
                text-color="white"
                style="cursor: pointer;"
                :color="inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.color : 'white'"
              >
                <span style="font-size: 20px;">{{ inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.name : '-' }}</span>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-3 mb-5 text-center">
              <h6 class="mb-3">
                Supplier Label
              </h6>
              <a

                @click="openItemShowImageForm(inventoryTag.supplier_label, inventoryTag.supplier_label_mime_type)"
              >
                <v-img
                  :src="convertedPhoto(inventoryTag.supplier_label, inventoryTag.supplier_label_mime_type)"
                  class="profile-photo-mini ml-3"
                />
              </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          :class="`pl-0 pt-0 pb-0 ${inventoryTag.item.photo && inventoryTag.item.mime_type ? '' : 'pr-6'}`"
          cols="auto"
          style=""
        >
          <div
            style="border-left: 1px solid; height: 90%; margin-left:30px;"
          />
        </v-col>
        <v-col
          v-if="inventoryTag.item.photo && inventoryTag.item.mime_type"
          class="pa-0 pr-3 pb-3"
          cols="auto"
          md="3"
          sm="8"
        >
          <v-img
            :src="convertedPhoto(inventoryTag.item.photo, inventoryTag.item.mime_type)"
            contain
            height="100%"
            width="100%"
            class="profile-photo"
          />
        </v-col>
        <v-col
          class="pa-0"
        >
          <v-form ref="editForm">
            <v-row>
              <!-- <v-col
                md="2"
                sm="4"
                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Class
                </h5>
                <inventory-class-select-element
                  v-model="inventoryClassId"
                  :is-add-all="false"
                  :outlined="true"
                  :is-label="false"
                  :readonly="true"
                />
              </v-col> -->
              <v-col
                md="6"
                sm="8"

                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Inventory Item
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.inventory_item_id"
                  :items="inventoryItems"
                  outlined
                  :readonly="true"
                  :style="'pointer-events: none'"
                  append-icon=""
                  :item-text="
                    (item) => item.material_id + ': ' + item.name
                  "
                  item-value="id"
                  :rules="[(v) => !!v || 'Inventory Item is required']"
                >
                  <template #item="{ item }">
                    <v-chip
                      class="mt-1 mb-1 mr-3"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      :color="item && item.classes ? item.classes.color : 'white'"
                    >
                      {{ item && item.classes ? item.classes.name : '' }}
                    </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                  </template>
                  <!-- eslint-disable-next-line vue/no-template-shadow -->
                  <template #selection="{ item }">
                    <v-chip
                      class="mt-1 mb-1 mr-3"
                      small
                      text-color="white"
                      style="cursor: pointer;"
                      :color="item && item.classes ? item.classes.color : 'white'"
                    >
                      {{ item && item.classes ? item.classes.name : '' }}
                    </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col
                v-if="inventoryTag && inventoryTag.item && (inventoryTag.item.classes.id !== inventoryClassIdGlass && inventoryTag.item.classes.id !== inventoryClassIdVWDGlass && inventoryTag.item.classes.id !== inventoryClassIdPanels && inventoryTag.item.classes.id !== inventoryClassIdSID && inventoryTag.item.classes.id != inventoryClassIdInst && inventoryTag.item.classes.id != inventoryClassIdParts && inventoryTag.item.classes.id != inventoryClassIdPaint && inventoryTag.item.classes.id != inventoryClassIdPatioDoor && inventoryTag.item.classes.id != inventoryClassIdEquipment)"
                md="2"
                sm="6"

                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Length<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.length_id"
                  :items="lengthsList"
                  append-icon=""
                  :readonly="isEdit ? false : true"
                  :style="isEdit ? '' : 'pointer-events: none'"
                  outlined
                  item-text="name"
                  item-value="id"
                  :rules="[(v) => !!v || 'Length is required']"
                  :loading="loadingLength"
                />
              </v-col>
              <v-col
                md="2"
                sm="6"

                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  UOM<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.uom_id"
                  :items="uomsList"
                  :readonly="isEdit ? false : true"
                  item-text="name"
                  item-value="id"
                  :style="isEdit ? '' : 'pointer-events: none'"
                  append-icon=""
                  outlined
                  :loading="loadingUom"
                  :rules="[(v) => !!v || 'UOM is required']"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="inventoryTag && inventoryTag.item && (inventoryTag.item.classes.id !== inventoryClassIdGlass && inventoryTag.item.classes.id !== inventoryClassIdVWDGlass && inventoryTag.item.classes.id !== inventoryClassIdSID && inventoryTag.item.classes.id != inventoryClassIdInst && inventoryTag.item.classes.id != inventoryClassIdParts && inventoryTag.item.classes.id != inventoryClassIdPaint && inventoryTag.item.classes.id != inventoryClassIdPatioDoor && inventoryTag.item.classes.id != inventoryClassIdEquipment)"
                md="4"
                sm="6"

                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Colour<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.color_id"
                  :items="colorsList"
                  :readonly="isEdit ? false : true"
                  :item-text="
                    (item) => item.code + (item.name ? ': ' + item.name : '')
                  "
                  item-value="id"
                  :style="isEdit ? '' : 'pointer-events: none'"
                  append-icon=""
                  outlined
                  :rules="[(v) => !!v || 'Colour is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes.id === inventoryClassIdPatioDoor"
                md="2"
                sm="6"
                class="text-center pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Door Height<RedAsterisk v-if="isEdit" />
                </h5>
                <v-text-field
                  v-model="inventoryTag.height"
                  outlined
                  :readonly="isEdit ? false : true"
                  :rules="[(v) => !!v || 'Door height is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes.id === inventoryClassIdPatioDoor"
                md="2"
                sm="6"
                class="text-center pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Door Width<RedAsterisk v-if="isEdit" />
                </h5>
                <v-text-field
                  v-model="inventoryTag.width"
                  outlined
                  :readonly="isEdit ? false : true"
                  :rules="[(v) => !!v || 'Door width is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes.id === inventoryClassIdPatioDoor"
                md="3"
                sm="6"
                class="pt-0 pr-1 pl-1 pb-0"
                style=""
              >
                <h5 class="h5">
                  Interior Color<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.color_id"
                  :items="colorsList"
                  item-text="code"
                  append-icon=""
                  item-value="id"
                  :readonly="isEdit ? false : true"
                  outlined
                  :rules="[(v) => !!v || 'Interior Color is required']"
                >
                  <template #selection="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="inventoryTag && inventoryTag.item && inventoryTag.item.classes.id === inventoryClassIdPatioDoor"
                md="3"
                sm="6"
                class="pt-0 pr-1 pl-1 pb-0"
                style=""
              >
                <h5 class="h5">
                  Exterior Color<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.ex_color_id"
                  :items="colorsList"
                  item-text="code"
                  append-icon=""
                  item-value="id"
                  :readonly="isEdit ? false : true"
                  outlined
                  :rules="[(v) => !!v || 'Exterior Color is required']"
                >
                  <template #selection="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                md="3"
                sm="6"

                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Location<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.location_id"
                  :items="locationsList"
                  :readonly="isEdit ? false : true"
                  item-text="name"
                  item-value="id"
                  append-icon=""
                  :style="isEdit ? '' : 'pointer-events: none'"
                  outlined
                  :rules="[(v) => !!v || 'Location is required']"
                />
              </v-col>
              <v-col
                md="3"
                sm="6"

                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Shelf<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.inventory_location_id"
                  :items="inventoryLocations"
                  :readonly="isEdit ? false : true"
                  item-text="name"
                  item-value="id"
                  :style="isEdit ? '' : 'pointer-events: none'"
                  append-icon=""
                  outlined
                  :rules="[(v) => !!v || 'Inv. Location is required']"
                />
              </v-col>
              <v-col
                md="2"
                sm="6"

                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  PO#
                </h5>
                <v-text-field
                  v-model="inventoryTag.po_number"
                  outlined
                  :readonly="isEdit ? false : true"
                  :style="isEdit ? '' : 'pointer-events: none'"
                />
              </v-col>
              <v-col
                v-if="inventoryClassId === inventoryClassIdSID"
                md="3"
                sm="2"

                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Size<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.door_size_id"
                  :items="doorSizes"
                  item-text="name"
                  :readonly="isEdit ? false : true"
                  append-icon=""
                  item-value="id"
                  outlined
                  :rules="[(v) => !!v || 'Size is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryClassId === inventoryClassIdSID && inventoryTag.inventory_item_id === inventoryIdSlab"
                md="3"
                sm="6"

                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Hinge<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.door_hinge_id"
                  :items="doorHinges"
                  item-text="name"
                  :readonly="isEdit ? false : true"
                  append-icon=""
                  item-value="id"
                  outlined
                  :rules="[(v) => !!v || 'Hinge is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryClassId === inventoryClassIdSID && inventoryTag.inventory_item_id === inventoryIdSlab"
                md="3"
                sm="6"

                class="pr-1 pl-1 pt-0 pb-0"
              >
                <h5 class="h5">
                  Swing<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.door_swing_id"
                  :items="doorSwings"
                  item-text="name"
                  append-icon=""
                  item-value="id"
                  :readonly="isEdit ? false : true"
                  outlined
                  :rules="[(v) => !!v || 'Swing is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryClassId === inventoryClassIdSID && inventoryTag.inventory_item_id === inventoryIdInsert"
                md="3"
                sm="6"

                class="pt-0 pr-1 pl-1"
              >
                <h5 class="h5">
                  Lite<RedAsterisk v-if="isEdit" />
                </h5>
                <v-autocomplete
                  v-model="inventoryTag.door_lite_id"
                  :items="doorLites"
                  item-text="name"
                  append-icon=""
                  item-value="id"
                  :readonly="isEdit ? false : true"
                  outlined
                  :rules="[(v) => !!v || 'Lite is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryTag && inventoryTag.item && (inventoryTag.item.classes.id === inventoryClassIdGlass || inventoryTag.item.classes.id === inventoryClassIdVWDGlass)"
                md="2"
                sm="6"
                class="text-center pr-1 pl-1"
              >
                <h5 class="h5">
                  Ven. Batch#<RedAsterisk v-if="isEdit" />
                </h5>
                <v-text-field
                  v-model="inventoryTag.batch_number"
                  outlined
                  :readonly="isEdit ? false : true"
                  :rules="[(v) => !!v || 'Vendor Batch# is required']"
                />
              </v-col>
              <v-col
                v-if="inventoryTag && inventoryTag.item && (inventoryTag.item.classes.id === inventoryClassIdGlass || inventoryTag.item.classes.id === inventoryClassIdVWDGlass)"
                md="2"
                sm="6"
                class="text-center pr-1 pl-1"
              >
                <h5 class="h5">
                  Prod. Date<RedAsterisk v-if="isEdit" />
                </h5>
                <v-menu
                  ref="menu"
                  v-model="dateMenu"
                  :return-value.sync="inventoryTag.production_date"
                  transition="scale-transition"
                  offset-y
                  left
                  :disabled="isEdit ? false : true"
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="inventoryTag.production_date"
                      outlined
                      :readonly="isEdit ? false : true"
                      v-bind="attrs"
                      :rules="[(v) => !!v || ' Production Date is required']"
                      v-on="on"
                      @change="$refs.menu.save(inventoryTag.production_date)"
                    />
                  </template>
                  <v-date-picker
                    v-model="inventoryTag.production_date"
                    no-title
                    scrollable
                    :readonly="isEdit ? false : true"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="inventoryTag.production_date = null"
                    >
                      Clear
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-1" />
            </v-row>
            <v-row
              v-if="inventoryClassId === inventoryClassIdPatioDoor"
            >
              <v-col
                class="pt-0 pr-6"
                cols="auto"
              >
                <v-radio-group
                  v-model="inventoryTag.door_screen_id"
                  hide-details
                  class="mt-0"
                  :readonly="isEdit ? false : true"
                >
                  <template #label>
                    <span style="font-size: 17.264px; color: #000; font-weight: bold;">Screen<RedAsterisk v-if="isEdit" /></span>
                  </template>
                  <v-row>
                    <v-col cols="auto">
                      <v-radio
                        label="WITH SCREEN"
                        :value="1"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-radio
                        label="WITHOUT SCREEN"
                        :value="2"
                      />
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
              <v-col
                class="pt-0 pr-6"
                cols="auto"
              >
                <v-radio-group
                  v-model="inventoryTag.is_fritted"
                  hide-details
                  class="mt-0"
                  :readonly="isEdit ? false : true"
                >
                  <template #label>
                    <span style="font-size: 17.264px; color: #000; font-weight: bold;">Fritted<RedAsterisk v-if="isEdit" /></span>
                  </template>
                  <v-row>
                    <v-col cols="auto">
                      <v-radio
                        label="FRITTED"
                        :value="1"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-radio
                        label="NON-FRITTED"
                        :value="0"
                      />
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row
              v-if="inventoryClassId === inventoryClassIdSID"
            >
              <v-col
                class="pa-4 mb-2"
                cols="11"
                style="background-color: #E8F5E9; border: 2px solid rgba(0,0,0,0.2)"
              >
                <v-row>
                  <v-col
                    class="pt-0 pr-6"
                    cols="auto"
                  >
                    <v-radio-group
                      v-model="inventoryTag.is_painted"
                      hide-details
                      class="mt-0"
                      :readonly="isEdit ? false : true"
                    >
                      <template #label>
                        <span style="font-size: 17.264px; color: #000; font-weight: bold;">Paint<RedAsterisk v-if="isEdit" /></span>
                      </template>
                      <v-row>
                        <v-col cols="auto">
                          <v-radio
                            label="PAINTED"
                            :value="1"
                          />
                        </v-col>
                        <v-col cols="auto">
                          <v-radio
                            label="NON-PAINTED"
                            :value="0"
                          />
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    v-if="inventoryTag.is_painted == 1"
                    md="3"
                    sm="6"
                    class="pt-0 pr-1 pl-1 pb-0"
                    style=""
                  >
                    <h5 class="h5">
                      {{ inventoryTag.inventory_item_id !== inventoryIdSweep ? 'Interior Color' : 'Color' }}<RedAsterisk v-if="isEdit" />
                    </h5>
                    <v-autocomplete
                      v-model="inventoryTag.color_id"
                      :items="colorsList"
                      item-text="code"
                      append-icon=""
                      item-value="id"
                      outlined
                      :readonly="isEdit ? false : true"
                      :rules="[(v) => !!v || `${inventoryTag.inventory_item_id !== inventoryIdSweep ? 'Interior Color' : 'Color'} is required`]"
                    >
                      <template #selection="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                      <template #item="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    v-if="inventoryTag.is_painted == 1 && inventoryTag.inventory_item_id !== inventoryIdSweep"
                    md="3"
                    sm="6"
                    class="pt-0 pr-1 pl-1 pb-0"
                    style=""
                  >
                    <h5 class="h5">
                      Exterior Color<RedAsterisk v-if="isEdit" />
                    </h5>
                    <v-autocomplete
                      v-model="inventoryTag.ex_color_id"
                      :items="colorsList"
                      item-text="code"
                      append-icon=""
                      item-value="id"
                      :readonly="isEdit ? false : true"
                      outlined
                      :rules="[(v) => !!v || 'Exterior Color is required']"
                    >
                      <template #selection="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                      <template #item="{ item }">
                        <span>
                          {{ item.code }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span v-if="isCounted">Enter recounted quantity<RedAsterisk /></span>
          <span v-else>Enter counted quantity<RedAsterisk /></span>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col
          class="pl-0"
          md="5"
          sm="6"
        >
          <v-form ref="form">
            <v-text-field
              v-model="quantity"
              :rules="[(v) => !!v || 'Quantity is required']"
              required
              outlined
              type="number"
            />
          </v-form>
        </v-col>
        <v-col
          cols="4"
          md="5"
          sm="12"
        >
          <v-btn
            color="primary"
            x-large
            class="mr-4"
            @click="submit"
          >
            submit
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4"
            outlined
            x-large
            @click="cancel"
          >
            cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <h2 v-if="isActivatedVisible">
      <v-row>
        <v-col class="text-center">
          <v-row>
            <v-col>
              {{ topErrorMessage }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                class="mr-4"
                outlined
                x-large
                @click="redirectBack"
              >
                scan new qr code
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </h2>
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
    <inventory-tag-note-form
      ref="noteForm"
    />
  </v-container>
</template>

<script>
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import PhysicalCountApi from '@/api/PhysicalCountApi';
import InventoryTagNoteForm from '@/components/forms/inventoryTags/InventoryTagNoteForm';
import { mapState } from 'vuex';

export default {
  components: {
    InventoryItemShowImageForm,
    InventoryTagNoteForm,
  },
  data: () => ({
    qrCode: '',
    inventoryTag: null,
    lengthsList: [],
    uomsList: [],
    colorsList: [],
    inventoryItems: [],
    inventoryLocations: [],
    locationsList: [],
    isActivatedVisible: false,
    quantity: null,
    physicalCount: null,
    isCounted: false,
    isEdit: false,
    photo: null,
    mime_type: null,
    inventoryClasses: '',
    inventoryClassId: null,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdPanels: 8,
    inventoryClassIdSID: 9,
    inventoryClassIdInst: 4,
    inventoryClassIdParts: 11,
    inventoryClassIdPaint: 12,
    inventoryClassIdEquipment: 13,
    inventoryClassIdPatioDoor: 14,
    inventoryIdSlab: 1100,
    inventoryIdSweep: 1101,
    inventoryIdInsert: 1102,
    dateMenu: false,
    doorHinges: [],
    doorSwings: [],
    doorLites: [],
    doorSizes: [],
    loadingLength: false,
    loadingUom: false,
    topErrorMessage: 'THIS QR CODE DOES NOT SATISFY THE REQUIREMENTS OF THE ACTIVE PHYSICAL COUNT',

  }),
  computed: {
    ...mapState({
      user: 'user',
      invClasses: 'invClasses',
    }),
  },
  watch: {
    inventoryTag(value) {
      if (!value) return;
      if (value.item.class_names) {
        this.inventoryClasses = value.item.class_names;
      }
    },
    inventoryClassId() {
      this.getInventoryItems();
    },
    // eslint-disable-next-line func-names
    'inventoryTag.inventory_item_id': function (val) {
      if (val === this.inventoryIdSlab || val === this.inventoryIdSweep || val === this.inventoryIdInsert) {
        this.getDoorSizes();
      }

      if (val === this.inventoryIdSlab) {
        this.inventoryTag.door_lite_id = null;
      } else if (val === this.inventoryIdSweep) {
        this.inventoryTag.door_hinge_id = null;
        this.inventoryTag.door_swing_id = null;
        this.inventoryTag.door_lite_id = null;
        this.inventoryTag.ex_color_id = null;
      } else if (val === this.inventoryIdInsert) {
        this.inventoryTag.door_hinge_id = null;
        this.inventoryTag.door_swing_id = null;
      }

      this.getUoms();
      this.getLengths();
    },
    // eslint-disable-next-line func-names
    'inventoryTag.is_painted': function (val) {
      if (val === 0) {
        this.inventoryTag.color_id = null;
        this.inventoryTag.ex_color_id = null;
      }
    },
    // eslint-disable-next-line func-names
    'inventoryTag.location_id': function (value) {
      if (!value) return;
      this.getInventoryLocations();
    },
    // eslint-disable-next-line func-names
    'inventoryTag.production_date': function (val) {
      if (this.$refs.menu) { this.$refs.menu.save(val); }
    },
  },

  created() {
    this.qrCode = this.$route.params.code;
    this.init();
  },

  methods: {
    async init() {
      await this.getActivePhysicalCountList();
      this.getInventoryTag();
      this.getUoms();
      this.getColors();
      this.getLengths();
      this.getInventoryItems();
      this.getLocations();
      this.getInventoryLocations();
      this.getDoorHinges();
      this.getDoorSwings();
      this.getDoorLites();
    },

    // QR CODE IS THE SAME AS INVENTORY TAG
    async getInventoryTag() {
      if (!this.qrCode) return;
      const submitModel = {
        filters: {
          inventory_tag: this.qrCode,
          class_ids: this.physicalCountList.classes.map((x) => x.id),
          is_qty_on_hand_o: 0,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);
      if (res && res.status === 505) {
        this.topErrorMessage = res.message;
        this.isActivatedVisible = true;
        return;
      }
      if (!res || !res.data || !res.data[0]) {
        this.isActivatedVisible = true;
        return;
      }
      const data = res.data[0];

      this.isActivatedVisible = false;
      this.inventoryTag = data;
      this.inventoryClassId = this.inventoryTag.item.inventory_class_id;
      this.photo = data.item && data.item.photo ? data.item.photo : null;
      this.mime_type = data.item && data.item.mime_type ? data.item.mime_type : null;
      this.getPhysicalCount();
    },

    async getPhysicalCount() {
      if (!this.inventoryTag) return;
      const response = await PhysicalCountApi.getByList(this.physicalCountList.id, this.inventoryTag.inventory_tag);
      if (!response) {
        return;
      }
      if (response.id) {
        this.isCounted = true;
        // if physical count with this tag exists
        this.inventoryTag = response;
      }
    },

    async getActivePhysicalCountList() {
      this.physicalCountList = await this.$api.physicalCountList.getActive();
    },

    async getColors() {
      const submitModel = {
        filters: {
        },
      };

      this.colorsList = await this.$api.color.read(submitModel);
    },

    async getLengths() {
      this.loadingLength = true;
      const submitData = {
        filters:
        {
          inventory_item_id: this.inventoryTag ? this.inventoryTag.inventory_item_id : null,
          inventory_class_id: this.inventoryClassId,
        },
      };
      this.lengthsList = await this.$api.length.read(submitData);
      this.loadingLength = false;
    },

    async getInventoryItems() {
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };

      const res = await this.$api.inventoryItem.readData(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        return;
      }
      this.inventoryItems = res.data;
    },

    async getInventoryLocations() {
      this.$nextTick(async () => {
        const locationId = this.inventoryTag && this.inventoryTag.location_id ? this.inventoryTag.location_id : 1;
        const readModel = {
          filters:
        {
          location_id: locationId,
        },
        };
        this.inventoryLocations = await this.$api.inventoryLocation.read(readModel);
      });
    },

    async getLocations() {
      this.locationsList = await this.$api.location.getList();
    },

    async getUoms() {
      this.loadingUom = true;
      const submitData = {
        filters:
        {
          inventory_item_id: this.inventoryTag ? this.inventoryTag.inventory_item_id : null,
        },
      };
      this.uomsList = await this.$api.uom.read(submitData);
      this.loadingUom = false;
    },

    async getDoorHinges() {
      this.doorHinges = await this.$api.doorHinge.getList();
    },

    async getDoorSwings() {
      this.doorSwings = await this.$api.doorSwing.getList();
    },

    async getDoorLites() {
      this.doorLites = await this.$api.doorLite.getList();
    },

    async getDoorSizes() {
      this.doorSizes = await this.$api.doorSize.get(`${this.inventoryTag.inventory_item_id}`);
    },

    async submit() {
      if (!this.physicalCountList && !this.inventoryTag) return;
      if (!await this.$refs.form.validate() || !await this.$refs.editForm.validate()) return;

      this.$bus.$emit('loading');
      const data = {
        id: this.isCounted ? this.inventoryTag.id : 0,
        physical_count_list_id: this.physicalCountList.id,
        inventory_tag: this.inventoryTag.inventory_tag,
        inventory_item_id: this.inventoryTag.inventory_item_id,
        length_id: this.inventoryTag.length_id,
        uom_id: this.inventoryTag.uom_id,
        inventory_location_id: this.inventoryTag.inventory_location_id,
        color_id: this.inventoryTag.color_id,
        po_number: this.inventoryTag.po_number,
        quantity: this.quantity,
        location_id: this.inventoryTag.location_id ? this.inventoryTag.location_id : 1,
        created_by: this.user.id,
        code: this.qrCode,
        door_hinge_id: this.inventoryTag.door_hinge_id,
        door_swing_id: this.inventoryTag.door_swing_id,
        door_lite_id: this.inventoryTag.door_lite_id,
        door_size_id: this.inventoryTag.door_size_id,
        ex_color_id: this.inventoryTag.ex_color_id,
        is_painted: this.inventoryTag.is_painted,
        height: this.inventoryTag.height,
        width: this.inventoryTag.width,
        is_fritted: this.inventoryTag.is_fritted,
        door_screen_id: this.inventoryTag.door_screen_id,
      };

      if (this.inventoryTag.batch_number) {
        data.batch_number = this.inventoryTag.batch_number;
      }

      if (this.inventoryTag.production_date) {
        data.production_date = this.inventoryTag.production_date;
      }

      const response = await PhysicalCountApi.store(data);
      this.$bus.$emit('loading-stop');
      if (!response) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.isEdit = false;
      setTimeout(() => {
        this.$router.push('/physical-count-qr');
      }, 2000);
      // this.getInventoryTag();
    },

    convertedPhoto(photo, mimeType) {
      if (!photo) return '';
      return `data:${mimeType};base64, ${photo}`;
    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },

    cancel() {
      if (this.isEdit) {
        this.isEdit = false;
        this.getInventoryTag();
      } else {
        this.redirectBack();
      }
    },

    redirectBack() {
      this.$router.push('/physical-count-qr');
    },

    toggleEdit() {
      this.isEdit = !this.isEdit;
    },

    historyRedirect() {
      const transactionsTabIndex = 2;
      const transactionsTabId = 3;
      this.$store.commit('storeInventoryTab', transactionsTabIndex);
      this.$store.commit('storeInventoryTabId', transactionsTabId);
      this.$router.push({
        name: 'Inventory',
        params: {
          searchParam: `qr=${this.qrCode}`,
        },
      });
    },
    openNotes() {
      this.$refs.noteForm.open(this.qrCode);
    },

  },
};
</script>
<style scoped>
.profile-photo-mini{
  max-width: 80px;

}
.profile-photo{

  max-height: 400px;
  max-width: 400px;

}
.v-text-field >>> input {
    font-size: 1.5em !important;
}

.v-autocomplete >>> input{
    font-size: 1.5em !important;
}

.v-btn {
    font-size: 1.1em !important;
}

::v-deep .v-input--radio-group--column .v-input--radio-group__input > .v-label {
    padding-bottom: 17px;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details
{
  margin-bottom: 0px;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0px;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details[data-v-c940035e] {

}
</style>
