import moment from 'moment';

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};

export const getFileTimestamp = () => moment().format('YYYY-MM-DD_HH-mm-ss');

export const getFileTimestamp2 = () => moment().format('YYYYMMDDHHmmss');

export const currentDateTimeSimple = () => moment().format('MMM Do h:mm a');

export const convertDateTimeFormat = (inputDate) => {
    // Parse the input date
    const date = new Date(inputDate);

    // Format the date as "YYYY-MM-DD h:mm a" (e.g., 2023-10-24 9:25 PM)
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  
    return formattedDate;
;} 

export default {
  formatPhoneNumber,
  getFileTimestamp,
  currentDateTimeSimple,
  convertDateTimeFormat,
};
