/* eslint-disable no-param-reassign */
export default {
  isLoggedIn: (state) => !!state.token,
  user: (state) => state.user,
  userToken: (state) => state.token,
  userCanEdit: (state) => {
    if (!state.user) return;
    // eslint-disable-next-line consistent-return
    return !!state.user.roles.some((e) => e.name === 'Admin' || e.name === 'Read-Write');
  },
  appVersion: () => '3.6.26',
  selectedBom: (state) => state.selected_bom,
  selectedRma: (state) => state.selected_rma,
  selectedJobsTab: (state) => state.selected_jobs_tab,
  selectedInventoryTab: (state) => state.selected_inventory_tab,
  selectedEquipmentTab: (state) => state.selected_equipment_tab,
  equipmentList: (state) => state.equipment_list,
  jobsList: (state) => state.jobs_list,
  conditionsList: (state) => state.conditions_list,
  isEquipmentMovementFormOpen: (state) => state.equipment_movement_form_open,
  equipmentMovementFormType: (state) => state.equipment_movement_form_type,
  lastUpdatedExcelFile: (state) => state.excel_file,
  businessRules: (state) => state.business_rules,
  physicalCount: (state) => state.physicalCount,
  search: (state) => state.search,

  isPhysicalCountActivated: (state) => state.physicalCountList && state.physicalCountList.id,

  businessRulesGetBoolean: (state) => (recordName) => {
    const record = state.business_rules.find((x) => x.name === recordName);
    if (!record) return 0;
    return parseInt(record.value, 10);
  },

  getAdminModuleValue: (state) => (moduleName) => {
    const record = state.admin_modules.find((x) => x.name.toUpperCase() === moduleName.toUpperCase());
    if (!record) return null;
    return record.enabled;
  },

  getRoles: (state) => {
    if (!state.user.roles.length) return [];

    return state.user.roles.map((x) => x.id);
  },

  checkUserPermission: (state) => (roleNames) => {
    if (!state.user.roles.length) return false;

    const userRoleNames = state.user.roles.map((x) => x.name);

    // eslint-disable-next-line no-restricted-syntax
    for (const x of roleNames) {
      if (userRoleNames.includes(x)) return true;
    }
    return false;
  },

  checkUserPermissionExcept: (state) => (roleNames) => {
    if (!state.user.roles.length) return false;

    const userRoleNames = state.user.roles.filter((x) => !roleNames.includes(x.name)).map((x) => x.name);
    if (userRoleNames.length !== 0) return true;
    return false;
  },

  getUserRoute: (state) => {
    if (!state.user) return false;

    if (state.user_state) {
      return state.user_state;
    }

    const userRoleNames = state.user.roles.map((x) => x.name);
    if (userRoleNames.includes('Admin')) {
      // check clients module enabled
      if (state.admin_modules) {
        const record = state.admin_modules.find((x) => x.name.toUpperCase() === 'CLIENTS');
        if (record && record.enabled) {
          return '/clients';
        }
      }
      return '/jobs';
    }

    if (userRoleNames.includes('Associate')) {
      if (state.admin_modules) {
        const record = state.admin_modules.find((x) => x.name.toUpperCase() === 'CLIENTS');
        if (record && record.enabled) {
          return '/clients';
        }
      }
      return '/jobs';
    }

    if (userRoleNames.includes('Material Handler')) {
      return '/inventory';
    }

    if (userRoleNames.includes('Paint Handler')) {
      return '/inventory-qr';
    }

    if (userRoleNames.includes('Physical Count Handler')) {
      return '/physical-count-qr';
    }

    if (userRoleNames.includes('Equipment Handler')) {
      return '/equipment';
    }

    if (userRoleNames.includes('RM Receiving Handler')) {
      return '/rm-receiving-qr';
    }

    if (userRoleNames.includes('Glass Handler')) {
      return '/glass';
    }

    if (userRoleNames.includes('Approver') || userRoleNames.includes('Requester') || userRoleNames.includes('Shipper') || userRoleNames.includes('Installer')) {
      return '/truck-schedules/requests';
    }

    if (userRoleNames.includes('Production Supervisor') || userRoleNames.includes('Spandrel Paint Supervisor') || userRoleNames.includes('Production Admin')) {
      return '/production-schedules/1';
    }

    if (userRoleNames.includes('Project Manager') || userRoleNames.includes('Estimator')) {
      return '/scope-of-projects';
    }

    if (userRoleNames.includes('Accountant') || userRoleNames.includes('HR') || userRoleNames.includes('Time-Off User') || userRoleNames.includes('Time-Off Supervisor') || userRoleNames.includes('HR Manager')) {
      return '/time-offs';
    }

    // remove read, read-write later
    if (userRoleNames.includes('Read')) return '/jobs';

    if (userRoleNames.includes('Read-Write')) return '/jobs';

    if (userRoleNames.includes('Picker')) return '/part-pick-lists';

    if (userRoleNames.includes('Purchaser')) return '/inventory-po';

    return false;
  },

  getUserName: (state) => `${state.user.first_name} ${state.user.last_name}`,
};
