<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        class="pa-6"
        style="background-color: #e5fcec; border: 1.5px solid #E8E8DD;"
      >
        <v-row align="center">
          <v-col
            cols="auto"
            class="pr-0 pt-2"
          >
            <v-btn
              color="primary"
              icon
              @click="isOpen = !isOpen"
            >
              <v-icon v-if="isOpen">
                mdi-chevron-up
              </v-icon>
              <v-icon v-else>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <span style="color: #000000; font-size: 16px; font-weight: bold;">EXTRA ITEMS ({{ items.length }})</span>
          </v-col>
          <v-col
            v-if="!isItemOpen && !returnItem && !billOfLadingQr && !isBillOfLadingOpen"
            class="text-end"
          >
            <v-btn
              color="primary"
              outlined
              @click="open()"
            >
              <v-icon
                small
                class="mr-1"
              >
                fas fa-plus
              </v-icon>
              EXTRA ITEMS
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-show="isOpen">
          <v-col>
            <v-row
              v-if="isItemOpen || items.length > 0"
            >
              <v-col
                cols="12"
                class=""
              >
                <v-card
                  elevation="0"
                  outlined
                >
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :items-per-page="-1"
                    dense
                    item-key="temp_id"
                  >
                    <template #[`item.name`]="{ item }">
                      <span v-if="item.bill_of_lading_extra_item_type">
                        {{ item.bill_of_lading_extra_item_type.name }}
                      </span>
                    </template>
                    <template #[`item.weight`]="{ item }">
                      {{ item && item.weight ? `${item.weight.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}` : '-' }}
                    </template>
                    <template #[`item.total_weight`]="{ item }">
                      {{ item && item.weight && item.quantity ? `${parseFloat(getItemTotal(item)).toLocaleString('en-US', { maximumFractionDigits: 2 })}` : '-' }}
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-btn
                        v-if="!returnItem && !isBillOfLadingOpen"
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        small
                        @click="openEdit(item)"
                      >
                        EDIT
                      </v-btn>
                      <v-btn
                        v-if="!returnItem && !isBillOfLadingOpen"
                        class="ml-1 mt-1 mb-1"
                        color="error"
                        outlined
                        small
                        @click="softDelete(item)"
                      >
                        DELETE
                      </v-btn>
                    </template>
                    <template slot="body.append">
                      <tr>
                        <th class="text-end pr-1">
                          <span style="color: #000000; font-weight: bold; font-size: 14px;">Totals: </span>
                        </th>
                        <th class="text-end">
                          <span style="font-weight: bold; font-size: 14px;">{{ parseFloat(getTotalUnitWeight()).toLocaleString('en-US', { maximumFractionDigits: 2 }) }}</span>
                        </th>
                        <th class="text-end">
                          <span style="font-weight: bold; font-size: 14px;">{{ getTotalQuantity() }}</span>
                        </th>
                        <th class="text-end">
                          <span style="font-weight: bold; font-size: 14px;">{{ parseFloat(getTotalWeightKilograms()).toLocaleString('en-US', { maximumFractionDigits: 2 }) }}</span>
                        </th>
                        <th v-if="!billOfLadingQr"/>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="isItemOpen">
              <v-col>
                <v-row>
                  <v-col class="pb-0">
                    <zig-zag bg-color="green" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <span style="font-size: 16px; color: #000000; font-weight: bold;"> {{ items.some(x => x.temp_id === item.temp_id) ? 'UPDATE' : 'NEW' }} EXTRA ITEM</span>
                  </v-col>
                </v-row>
                <v-form ref="form">
                  <v-row>
                    <v-col
                      md="6"
                      sm="8"
                      class="pb-0"
                    >
                      <v-row>
                        <v-col class="pb-0">
                          <span style="color: #000000;">Available Extra Items</span><RedAsterisk />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-1">
                          <v-autocomplete
                            v-model="item.bill_of_lading_extra_item_type_id"
                            :items="billOfLadingExtraItemTypes"
                            item-text="name"
                            item-value="id"
                            solo
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="3"
                      md="3"
                      sm="6"
                      class="pb-0"
                    >
                      <v-row>
                        <v-col class="pb-0">
                          <span style="color: #000000;">Weight</span><RedAsterisk />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-1">
                          <v-text-field
                            v-model="item.weight"
                            placeholder="Enter Weight"
                            solo
                            type="number"
                            min="0"
                            suffix="kg"
                            :rules="[(v) => !!v || 'Weight is required']"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="3"
                      md="3"
                      sm="6"
                      class="pb-0"
                    >
                      <v-row>
                        <v-col class="pb-0">
                          <span style="color: #000000;">Quantity</span><RedAsterisk />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-1">
                          <v-text-field
                            v-model="item.quantity"
                            placeholder="Enter Quantity"
                            solo
                            type="number"
                            min="1"
                            :rules="[(v) => !!v || 'Quantity is required']"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      class="mr-2"
                      @click="close()"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="pl-2"
                  >
                    <v-btn
                      color="primary"
                      @click="addItem"
                    >
                      {{ items.some(x => x.temp_id === item.temp_id) ? 'UPDATE' : 'ADD' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ZigZag from '@/components/elements/ZigZag';

export default {
  components: {
    ZigZag,
  },
  props: {
    returnItem: {
      type: Boolean,
      default: false,
    },
    billOfLadingQr: {
      type: Boolean,
      default: false,
    },
    isBillOfLadingOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
    billOfLadingExtraItemTypes: [],
    item: null,
    itemDto: {
      // id: 0,
      bill_of_lading_extra_item_type_id: null,
      weight: null,
      quantity: null,
    },
    isOpen: true,
    isItemOpen: false,
    loadingextraItemTypes: false,
  }),
  computed: {
    ...mapState({}),
    ...mapGetters([]),
    headers() {
      let headers = [
        {
          text: 'Extra Item', value: 'name',
        },
        { 
          text: 'Unit Weight (kg)', value: 'weight', align: 'end', width: '10%',
        },
        {
          text: 'Quantity', value: 'quantity', align: 'end', width: '20%',
        },
        {
          text: 'Total Weight (kg)', value: 'total_weight', align: 'end', width: '20%',
        },
      ]

      if(!this.billOfLadingQr) {
        headers.push({
          text: 'Actions', value: 'actions', align: 'end', width: '15%',
        })
      }

      return headers;
    }
  },
  watch: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },

    async get(billOfLadingId) {
      const readModel = {
        filters:
        {
          bill_of_lading_id: billOfLadingId,
        },
      };

      const res = await this.$api.billOfLadingExtraItem.read(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || res.length === 0) {
        this.$bus.$emit('showError', 'Error reading extra items');
        return;
      }

      this.items = res.data;
    },

    async getExtraItemTypes() {
      this.loadingExtraItemTypes = true;

      const res = await this.$api.billOfLadingExtraItemType.get();
      if (!res || !res.length) {
        this.$bus.$emit(
          'showError',
          'Unable to retrieve available extra items',
        );
        return;
      }

      this.billOfLadingExtraItemTypes = res;
      this.loadingExtraItemTypes = false;
    },

    async open() {
      // await this.getExtraItemTypes();
      this.isOpen = true;
      this.isItemOpen = true;
      this.item = { ...this.itemDto };
      this.item.temp_id = Date.now();
    },

    close() {
      this.isItemOpen = false;
    },

    openEdit(item) {
      this.item = { ...item };
      this.isItemOpen = true;
    },

    addItem() {
      if (!this.$refs.form.validate()) return;
      const billOfLadingExtraItemType = this.billOfLadingExtraItemTypes.find((x) => x.id === this.item.bill_of_lading_extra_item_type_id);
      const item = this.items.find((x) => x.temp_id === this.item.temp_id);
      if (item) {
        const index = this.items.findIndex((x) => x.temp_id === this.item.temp_id);
        this.item.bill_of_lading_extra_item_type = billOfLadingExtraItemType;
        this.items.splice(index, 1, { ...this.item });
      } else {
        if (billOfLadingExtraItemType) {
          this.item.bill_of_lading_extra_item_type = billOfLadingExtraItemType;
          this.item.bill_of_lading_extra_item_type_id = billOfLadingExtraItemType.id;
        }
        this.items.push(JSON.parse(JSON.stringify(this.item)));
      }

      // this.$emit('addExtraItem', { ...this.item });

      this.close();
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      let index;
      if (item.id) {
        index = this.items.findIndex((x) => x.id === item.id);
      } else {
        index = this.items.findIndex((x) => x.temp_id === item.temp_id);
      }

      // const removedItem = this.items[index];
      this.items.splice(index, 1);

      // this.$emit('removeExtraItem', { ...removedItem });
    },

    submit() {
      //
    },

    getItemTotal(item) {
      return item.weight * item.quantity;
    },

    getTotalUnitWeight() {
      return this.items.reduce((acc, extraItem) => acc + parseFloat(extraItem.weight), 0);
    },

    getTotalQuantity() {
      return this.items.reduce((acc, extraItem) => acc + parseInt(extraItem.quantity), 0);
    },

    getTotalWeightKilograms() {
      return this.items.reduce((acc, extraItem) => acc + (parseFloat(extraItem.weight) * parseInt(extraItem.quantity)), 0);
    },
  },
};
</script>
<style scoped>
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  background-color: #FFFFFF !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th > span {
  color: #000000;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot
{
  border: 1px solid rgba(0,0,0,0.4) !important;
}
</style>
