var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-spacer'),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success","outlined":""},on:{"click":_vm.runWebhookAll}},[_vm._v(" GENERATE ORD ACK ALL ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openManualInvoice}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" NEW MANUAL INVOICE ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":_vm.pagination.itemsPerPage,"search":_vm.search,"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.windowHeight,"server-items-length":_vm.total},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.work_order",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-weight":"bold"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.work_order)+" ")])]}},{key:"item.job_description",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{style:(((item.job_description === 'TOTAL' ? 'float:right' : '') + "; " + (item.job_description === 'TOTAL' ? 'font-weight: bold' : ''))),attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.job_description)+" ")])]}},{key:"item.total_quantity",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{style:(("white-space: nowrap; float:right; " + (item.job_description === 'TOTAL' ? 'font-weight: bold' : ''))),attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.total_quantity)+" ")])]}},{key:"item.total_sqft",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{style:(("white-space: nowrap; float:right; " + (item.job_description === 'TOTAL' ? 'font-weight: bold' : ''))),attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.total_sqft ? parseFloat(item.total_sqft).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')+" ")])]}},{key:"item.sub_total",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{style:(("white-space: nowrap; float:right; " + (item.job_description === 'TOTAL' ? 'font-weight: bold' : ''))),attrs:{"queries":_vm.search}},[_vm._v(" $"+_vm._s(item.sub_total ? parseFloat(item.sub_total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{style:(("white-space: nowrap; float:right; " + (item.job_description === 'TOTAL' ? 'font-weight: bold' : ''))),attrs:{"queries":_vm.search}},[_vm._v(" $"+_vm._s(item.total ? parseFloat(item.total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.job_description !== 'TOTAL')?_c('v-chip',{attrs:{"small":"","color":item && item.status_id == 1 ? '#F7A51C' : '#357A38',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.status_id == 1 ? 'PENDING' : 'INVOICED')+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.job_description != 'TOTAL')?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"success","outlined":"","small":""},on:{"click":function($event){return _vm.runWebhook(item)}}},[_vm._v(" ORDER ACK PDF ")]):_vm._e(),(item.job_description != 'TOTAL')?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"purple","outlined":"","small":""},on:{"click":function($event){return _vm.runWebhookInvoice(item)}}},[_vm._v(" INVOICE PDF ")]):_vm._e(),(item.job_description != 'TOTAL')?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":"","disabled":item.status_id == 2},on:{"click":function($event){return _vm.createInvoice(item, _vm.typeIdInvoice)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" INVOICE ")],1):_vm._e()]}}],null,true)})],1)],1)],1),_c('the-dialog-box',{ref:"dialogBox",attrs:{"submit":_vm.getOrderAcknowledgment,"submit-btn-text":'Submit',"persistent":true,"btn-loading":_vm.loading}},[_c('template',{slot:"header"},[_c('span',[_vm._v("Enter password")])]),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-text-field',{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"rules":[function (v) { return !!v || 'Password is required'; }]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.passwordForm),callback:function ($$v) {_vm.passwordForm=$$v},expression:"passwordForm"}})],1)],1)],1)],2),_c('invoice-form',{ref:"itemForm",on:{"success":_vm.getOrderAcknowledgment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }