/* eslint-disable vue/component-definition-name-casing */
import Vue from 'vue';
import Snackbar from '@/components/Snackbar';
import RedAsterisk from '@/components/RedAsterisk';
import TheDialogBox from '@/components/forms/TheDialogBox';
import TextHighlight from 'vue-text-highlight';
import InventoryClassSelectElement from '@/components/elements/InventoryClassSelectElement';
import ConfirmForm from '@/components/elements/ConfirmForm';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
// import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/axios';
import '@/api/ApiFactories.js';

Vue.config.productionTip = false;
Vue.component('Snackbar', Snackbar);
Vue.component('RedAsterisk', RedAsterisk);
Vue.component('the-dialog-box', TheDialogBox);
Vue.component('text-highlight', TextHighlight);
Vue.component('inventory-class-select-element', InventoryClassSelectElement);
Vue.component('confirm-form', ConfirmForm);

Vue.prototype.$bus = new Vue();
Vue.use(PrimeVue);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
