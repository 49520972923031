<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :min-width="600"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Employee</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.first_name"
            :rules="[(v) => !!v || 'First Name is required']"
          >
            <template slot="label">
              First Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.last_name"
            :rules="[(v) => !!v || 'Last Name is required']"
          >
            <template slot="label">
              Last Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.employee_number"
            :rules="[(v) => !!v || 'Employee Number is required', (v) => !!v && v.length <= 45 || 'Max 45 characters']"
            counter="45"
          >
            <template slot="label">
              Employee Number<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-menu
            v-model="startDateMenu"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            offset-overflow
            min-width="auto"
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="item.start_date"
                append-icon="mdi-calendar"
                v-bind="attrs"
                label="Start Date"
                v-on="on"
                clearable
              />
            </template>
            <v-date-picker
              v-model="item.start_date"
              no-title
              scrollable
              @input="
                () => {
                  updateAvailableDaysOff();
                  startDateMenu = false;
                }
              "
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="item.supervisors"
            :items="supervisors"
            item-text="full_name"
            item-value="id"
            label="Supervisors"
            clearable
            multiple
            return-object
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.department_id"
            :items="departments"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Department is required']"
          >
            <template slot="label">
              Department<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="item.available_day_off"
            type="number"
          >
            <template slot="label">
              Available Day Off
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="item.email"
          >
            <template slot="label">
              Email
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-6"
        >
          <VuePhoneNumberInput
            v-model="item.phone_number"
            default-country-code="CA"
            @update="onUpdate"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  components: {
    VuePhoneNumberInput,
  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      first_name: null,
      last_name: null,
      employee_number: null,
      email: null,
      phone_number: null,
      department_id: null,
      available_day_off: 0,
      supervisors: [],
      start_date: null,
    },
    phvalid: false,
    departments: [],
    supervisors: [],
    userRoleIdTimeOffSupervisor: 28,
    startDateMenu: false,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
          is_username: false,
        },
      };
      const res = await this.$api.user.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async getDepartments() {
      this.departments = await this.$api.department.get();
    },

    async getSupervisors() {
      const submitModel = {
        filters: {
          role_id: this.userRoleIdTimeOffSupervisor,
        },
      };
      this.supervisors = await this.$api.user.read(submitModel);
    },

    async submit() {
      if (!this.item) return false;

      const res = await this.$api.user.store(this.item);

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
      this.getDepartments();
      this.getSupervisors();
    },

    onUpdate(payload) {
      this.phvalid = payload.isValid;
    },

    updateAvailableDaysOff() {
      const workingYears = this.calculateWorkingPeriod();
      if (workingYears >= 4) {
        this.item.available_day_off = 15;
      } else if (workingYears >= 1) {
        this.item.available_day_off = 10;
      } else {
        this.item.available_day_off = 0;
      }
    },

    calculateWorkingPeriod() {
      const today = new Date();
      const startDate = new Date(this.item.start_date);
      const startYear = startDate.getFullYear();
      const currentYear = today.getFullYear();
      const fullYears = currentYear - startYear;

      // Check if the current date is before the start date in the current year
      if (today < new Date(today.getFullYear(), startDate.getMonth(), startDate.getDate())) {
        return fullYears - 1;
      }
      return fullYears;
    },
  },
};
</script>
