<template>
  <v-container
    fluid
  >
    <v-row class="align-center">
      <v-spacer />
      <v-col
        cols="auto"
        class="pr-0"
      >
        <v-btn
          color="success"
          outlined
          @click="runWebhookAll"
        >
          GENERATE ORD ACK ALL
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
      >
        <v-btn
          color="primary"
          outlined
          @click="openManualInvoice"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          NEW MANUAL INVOICE
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #[`item.work_order`]="{ item }">
              <text-highlight
                :queries="search"
                style="font-weight: bold;"
              >
                {{ item.work_order }}
              </text-highlight>
            </template>
            <template #[`item.job_description`]="{ item }">
              <text-highlight
                :queries="search"
                :style="`${item.job_description === 'TOTAL' ? 'float:right' : ''}; ${item.job_description === 'TOTAL' ? 'font-weight: bold' : ''}`"
              >
                {{ item.job_description }}
              </text-highlight>
            </template>
            <template #[`item.total_quantity`]="{ item }">
              <text-highlight
                :queries="search"
                :style="`white-space: nowrap; float:right; ${item.job_description === 'TOTAL' ? 'font-weight: bold' : ''}`"
              >
                {{ item.total_quantity }}
              </text-highlight>
            </template>
            <template #[`item.total_sqft`]="{ item }">
              <text-highlight
                :queries="search"
                :style="`white-space: nowrap; float:right; ${item.job_description === 'TOTAL' ? 'font-weight: bold' : ''}`"
              >
                {{ item.total_sqft ? parseFloat(item.total_sqft).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00' }}
              </text-highlight>
            </template>
            <template #[`item.sub_total`]="{ item }">
              <text-highlight
                :queries="search"
                :style="`white-space: nowrap; float:right; ${item.job_description === 'TOTAL' ? 'font-weight: bold' : ''}`"
              >
                ${{ item.sub_total ? parseFloat(item.sub_total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00' }}
              </text-highlight>
            </template>
            <template #[`item.total`]="{ item }">
              <text-highlight
                :queries="search"
                :style="`white-space: nowrap; float:right; ${item.job_description === 'TOTAL' ? 'font-weight: bold' : ''}`"
              >
                ${{ item.total ? parseFloat(item.total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00' }}
              </text-highlight>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                v-if="item.job_description !== 'TOTAL'"
                small
                :color="item && item.status_id == 1 ? '#F7A51C' : '#357A38'"
                text-color="white"
              >
                {{ item && item.status_id == 1 ? 'PENDING' : 'INVOICED' }}
              </v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                v-if="item.job_description != 'TOTAL'"
                class="ml-1 mt-1 mb-1"
                color="success"
                outlined
                small
                @click="runWebhook(item)"
              >
                ORDER ACK PDF
              </v-btn>
              <v-btn
                v-if="item.job_description != 'TOTAL'"
                class="ml-1 mt-1 mb-1"
                color="purple"
                outlined
                small
                @click="runWebhookInvoice(item)"
              >
                INVOICE PDF
              </v-btn>
              <v-btn
                v-if="item.job_description != 'TOTAL'"
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                :disabled="item.status_id == 2"
                @click="createInvoice(item, typeIdInvoice)"
              >
                <v-icon
                  x-small
                  class="mr-1"
                >
                  fas fa-plus
                </v-icon>
                INVOICE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <the-dialog-box
      ref="dialogBox"
      :submit="getOrderAcknowledgment"
      :submit-btn-text="'Submit'"
      :persistent="true"
      :btn-loading="loading"
    >
      <template slot="header">
        <span>Enter password</span>
      </template>
      <template slot="body">
        <v-row>
          <v-col
            class="pb-0"
          >
            <v-text-field
              v-model="passwordForm"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Password is required']"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
      </template>
    </the-dialog-box>
    <invoice-form
      ref="itemForm"
      @success="getOrderAcknowledgment"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import InvoiceForm from '@/components/forms/invoices/InvoiceForm.vue';

export default {
  components: {
    InvoiceForm,
  },
  props: {

  },
  data: () => ({
    items: [],
    total: 0,
    loading: false,
    pagination: {
      itemsPerPage: 1000,
      page: 1,
    },
    headers: [
      {
        text: 'Name', value: 'work_order',
      },
      {
        text: 'Description', value: 'job_description',
      },
      {
        text: 'Total Qty.', value: 'total_quantity', class: 'text-end',
      },
      {
        text: 'Total Sqft.', value: 'total_sqft', class: 'text-end',
      },
      {
        text: 'Sub Total', value: 'sub_total', class: 'text-end',
      },
      {
        text: 'Total', value: 'total', class: 'text-end',
      },
      {
        text: 'Status', value: 'status', class: 'text-center', align: 'center',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '30%', class: 'pl-1 pr-1',
      },
    ],
    footer: {
      'items-per-page-options': [100, 500, 1000, -1],
    },
    topSpaceHeight: 200,
    windowHeight: 1000,
    passwordForm: null,
    password: null,
    showPassword: false,
    typeIdInvoice: 1,
    typeIdCreditMemo: 2,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    search() {
      this.getOrderAcknowledgment();
    },
    pagination() {
      this.getOrderAcknowledgment();
    },
  },
  created() {
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    if (!this.passwordForm) {
      this.$refs.dialogBox.open();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.getOrderAcknowledgment();
    },

    async getOrderAcknowledgment() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      if (!this.password) this.password = JSON.parse(JSON.stringify(this.passwordForm));
      const submitModel = {
        filters: {
          password: this.password,
        },
        search: this.search,
        pagination: this.pagination,
      };
      const res = await this.$api.orderAcknowledgment.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return false;
      if (!res || !res.data) {
        this.items = [];
        this.loading = false;
        this.password = null;
        this.$bus.$emit('showError', 'Password is incorrect. Please try again.');
        return false;
      }
      this.items = res.data;
      this.total = res.total;
      // eslint-disable-next-line radix
      const totalQty = this.items.reduce((acc, item) => acc + parseInt(item.total_quantity), 0);
      const totalSqft = this.items.reduce((acc, item) => acc + parseFloat(item.total_sqft), 0);
      const subTotal = this.items.reduce((acc, item) => acc + parseFloat(item.sub_total), 0);
      const total = this.items.reduce((acc, item) => acc + parseFloat(item.total), 0);
      this.items.push({
        work_order: '',
        job_description: 'TOTAL',
        total_quantity: totalQty,
        total_sqft: totalSqft,
        sub_total: subTotal,
        total,
      });
      this.loading = false;
      return true;
    },

    async runWebhookAll() {
      this.$bus.$emit('loading');
      const webhookModuleIdAccountingAll = 42;
      const webhookData = {
        module_id: webhookModuleIdAccountingAll,
        type: 'xls',
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Cost_Accounting_${now}`;

      const file = new Blob([res], {
        type: 'application/vnd.ms-excel',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    async openManualInvoice() {
      this.$refs.itemForm.open(null, 1);
    },

    async createInvoice(item, typeId) {
      const submitData = {
        filters: { work_order: item.work_order },
      };
      const jobRes = await this.$api.job.read(submitData);
      if (!jobRes) return;
      const job = jobRes[0];
      if (!job || !job.id) return;
      this.$refs.itemForm.open(null, 0, job.id, [item], typeId);
    },

    async runWebhook(item) {
      this.$bus.$emit('loading');
      const webhookModuleIdAccounting = 43;
      const webhookData = {
        module_id: webhookModuleIdAccounting,
        work_order: item.work_order,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.work_order}_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    async runWebhookInvoice(item) {
      if (!item.invoice_id) return;
      this.$bus.$emit('loading');
      const webhookModuleIdInvoice = 47;
      const webhookData = {
        module_id: webhookModuleIdInvoice,
        id: item.invoice_id,
        inventory_class_id: item.inventory_class_id,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.work_order}_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) {
        this.pagination = pagination;
      }
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },
  },
};
</script>

<style scoped>

</style>
