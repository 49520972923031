<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        class="text-end pt-0"
      >
        <v-btn
          v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdHR)"
          color="primary"
          outlined
          @click="open"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new employee
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            id="time-off-employee-table"
            :headers="headers"
            :items="items"
            :footer-props="itemsTableFooterProps"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            fixed-header
            :height="windowHeight"
            dense
            :server-items-length="total"
            @update:options="updateOptions"
          >
            <template #[`item.full_name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.full_name ? item.full_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.employee_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.employee_number ? item.employee_number : '' }}
              </text-highlight>
            </template>
            <template #[`item.department`]="{ item }">
              <v-chip
                v-if="item && item.department"
                small
                :color="item && item.department && item.department.color ? item.department.color : ''"
                text-color="white"
              >
                <text-highlight :queries="search">
                  {{ item && item.department && item.department.name ? item.department.name : '' }}
                </text-highlight>
              </v-chip>
            </template>
            <template #[`item.start_date`]="{ item }">
              <text-highlight :queries="search">
                {{ item.start_date ? item.start_date : '' }}
              </text-highlight>
            </template>
            <template #[`item.email`]="{ item }">
              <text-highlight :queries="search">
                {{ item.email ? item.email : '' }}
              </text-highlight>
            </template>
            <template #[`item.phone_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.phone_number ? item.phone_number : '' }}
              </text-highlight>
            </template>
            <template #[`item.supervisors`]="{ item }">
              <v-row v-if="item.supervisors && item.supervisors.length > 1">
                <v-col class="pl-1">
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on }">
                      <span
                        style="color: #1976d2;"
                        v-on="on"
                      >SUPERVISORS</span>
                    </template>
                    <span
                      v-for="p in item.supervisors"
                      :key="p.id"
                    >
                      {{ `${p.first_name} ${p.last_name}` }}<br>
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col
                  v-for="p, idx in item.supervisors"
                  :key="p.id"
                  cols="auto"
                  class="pr-0 pl-1"
                >
                  <span>
                    {{ `${p.first_name} ${p.last_name}` }}<span v-if="idx !== item.supervisors.length - 1">,</span>
                  </span>
                </v-col>
              </v-row>
            </template>
            <template #[`item.available_day_off`]="{ item }">
              <text-highlight :queries="search">
                {{ item.available_day_off ? item.available_day_off : '' }}
              </text-highlight>
            </template>
            <template #[`item.vacation_days_taken`]="{ item }">
              <text-highlight :queries="search">
                {{ item.vacation_days_taken ? item.vacation_days_taken : '' }}
              </text-highlight>
            </template>
            <template #[`item.vacation_days_remaining`]="{ item }">
              <text-highlight :queries="search">
                {{ item.vacation_days_remaining ? item.vacation_days_remaining : '' }}
              </text-highlight>
            </template>
            <template #[`item.vacation_days_exceeded`]="{ item }">
              <text-highlight :queries="search">
                {{ item.vacation_days_exceeded ? item.vacation_days_exceeded : '' }}
              </text-highlight>
            </template>
            <template #[`item.created_at_short`]="{ item }">
              <text-highlight :queries="search">
                {{ item.created_at_short ? item.created_at_short : '' }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="warning"
                outlined
                small
                @click="viewForms(item)"
              >
                VIEW FORMS
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEdit(item)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <TimeOffEmployeeForm
      ref="timeOffEmployeeForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TimeOffEmployeeForm from '@/components/forms/timeOffs/TimeOffEmployeeForm';
import PrimeDataTable from '@/components/PrimeDataTable/PrimeDataTable';

export default {
  components: {
    TimeOffEmployeeForm,
    PrimeDataTable,
  },
  data: () => ({
    loading: true,
    items: [],
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, 100, -1],
    },
    topSpaceHeight: 270,
    windowHeight: 1000,
    pagination: {
      itemsPerPage: 20,
      page: 1,
      sortBy: 'id',
      sortDesc: true,
    },
    userRoleIdAdmin: 3,
    userRoleIdAccountant: 24,
    userRoleIdHR: 25,

    headers: [
      {
        text: 'Full Name',
        value: 'full_name',
        sortable: true,
      },
      {
        text: 'Employee Number',
        value: 'employee_number',
        width: '5%',
        sortable: true,
      },
      {
        text: 'Department',
        value: 'department',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Start Date',
        value: 'start_date',
        sortable: false,
      },
      {
        text: 'Email',
        value: 'email',
        sortable: false,
      },
      {
        text: 'Phone',
        value: 'phone_number',
        sortable: false,
      },
      {
        text: 'Supervisors',
        value: 'supervisors',
        sortable: false,
      },
      {
        text: 'Available Days Off',
        value: 'available_day_off',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Days Off Taken',
        value: 'vacation_days_taken',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Days Off Remaining',
        value: 'vacation_days_remaining',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Days Off Exceeded',
        value: 'vacation_days_exceeded',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Created On',
        value: 'created_at_short',
        sortable: false,
      },
      {
        text: 'Actions', value: 'actions', width: '20%', align: 'end', sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
      timeOffState: (state) => state.timeOffState,
    }),
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
  },
  watch: {
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
  },
  created() {
    this.init();
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.roles = this.user.roles.map((x) => x.id);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {

    async init() {
      this.get();
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {
          is_username: false,
          is_vacation_days: true,
        },
        pagination: this.pagination,
        search: this.search,
      };

      const res = await this.$api.user.read(submitModel, null, 'read/paginated');
      const cancelCode = 3001;
      if (res === cancelCode) {
        return;
      }
      if (!res || !res.data) {
        this.$bus.$emit('showError');
        this.items = [];
        this.total = 0;
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;
    },

    open() {
      this.$refs.timeOffEmployeeForm.open();
    },

    openEdit(id) {
      this.$refs.timeOffEmployeeForm.open(id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    async softDelete(data) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!data.id) return;

      const res = await this.$api.user.delete(data.id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    viewForms(data) {
      if (this.roles.includes(this.userRoleIdAdmin) || this.roles.includes(this.userRoleIdHR)) {
        this.storeState('tab', 1);
      } else if (this.roles.includes(this.userRoleIdAccountant)) {
        this.storeState('tab', 0);
      }
      this.storeState('filterUserId', data.id);
      this.storeState('filterLeaveTypeId', null);
      this.storeState('filterStatusId', null);
    },

    removeNumbersAfterDecimal(num) {
    // Convert number to string and split by decimal point
      const parts = num.toString().split('.');
      // Check if the decimal part is "0"
      if (parts[1] === '0') {
        // Return only the integer part
        return parts[0];
      }
      // Return the original number as it is
      return num.toString();
    },

    updateOptions(newOptions) {
      this.pagination = newOptions;
      this.storeState('pagination', newOptions);
    },
    
    storeState(property, value) {
      const state = this.timeOffState;
      state[property] = value;
      this.$store.commit('storeTimeOffState', state);
    },

  },
};
</script>
<style scoped></style>
