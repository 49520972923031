<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="1"
        class="pb-5"
      >
        <inventory-class-select-element
          v-model="inventoryClassIdRequest"
          :hide-details="true"
          :is-state="true"
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-5"
      >
        <v-select
          v-model="filterStatusRequest"
          label="Status"
          :items="statuses"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="3"
        class="pb-5"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobs"
          hide-details="true"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          item-value="id"
          clearable
          :single-select="false"
        >
          <template #selection="{ item }">
            <v-chip
              v-if="item.sub_class"
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.sub_class ? item.sub_class.color : 'white'"
            >
              {{ item && item.sub_class ? item.sub_class.name : '' }}
            </v-chip>
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
          <template #item="{ item }">
            <v-chip
              v-if="item.sub_class"
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.sub_class ? item.sub_class.color : 'white'"
            >
              {{ item && item.sub_class ? item.sub_class.name : '' }}
            </v-chip>
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="2"
        class="pb-5"
      >
        <v-select
          v-model="filterTruckType"
          label="Truck Type"
          :items="truckTypes"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-menu
          ref="menu"
          v-model="dateMenu"
          :return-value.sync="filterDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterDate"
              label="Delivery Date"
              readonly
              v-bind="attrs"
              clearable
              v-on="on"
              @change="$refs.menu.save(filterDate)"
            />
          </template>
          <v-date-picker
            v-model="filterDate"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterDate = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer />
      <v-col
        v-show="!roles.includes(userRoleIdInstaller)"
        class="text-end pb-0"
      >
        <v-btn
          v-if="!roles.includes(userRoleIdShipper)"
          color="primary"
          outlined
          @click="openRequestForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Request
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="requestHeaders"
            :items="requests"
            :footer-props="requestFooterProps"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="requestLoading"
            dense
            @pagination="updatePagination"
          >
            <template #[`item.class`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'grey'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : 'All' }}
              </v-chip>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                small
                :color="item && item.status ? item.status.color : ''"
                text-color="white"
              >
                {{ item && item.status ? item.status.name : '' }}
              </v-chip>
            </template>
            <template #[`item.id`]="{ item }">
              <text-highlight
                :queries="search"
                style="font-weight: bold;"
              >
                {{ item.id }}
              </text-highlight>
            </template>
            <template #[`item.date`]="{ item }">
              <text-highlight
                :queries="search"
                style="font-weight: bold; font-size: 14px !important;"
              >
                {{ item.date }}
              </text-highlight>
            </template>
            <template #[`item.job`]="{ item }">
              <span>
                <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
              </span>
            </template>
            <template #[`item.floor_number`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.floor_number }}
              </text-highlight>
            </template>
            <template #[`item.created_at_short`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.created_at_short }}
              </text-highlight>
            </template>
            <template #[`item.created_by_name`]="{ item }">
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span v-on="on"><v-icon>fas fa-user-friends</v-icon></span>
                </template>
                <v-container style="min-width: 300px;">
                  <v-row>
                    <v-col
                      class="pb-1 pt-0"
                    >
                      <span style="color: white; font-size: 16px;">Created: </span><span
                        style="color: white; font-size: 16px;"
                        class="pl-5"
                      >{{ item.created_by_name }}, {{ item.created_at }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="item.installer"
                      class="pb-1 pt-0"
                    >
                      <span style="color: white; font-size: 16px;">Installer: </span><span
                        style="color: white; font-size: 16px;"
                        class="pl-5"
                      >{{ item.installer_name }}, {{ item.created_at }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.approved_by">
                    <v-col
                      class="pt-0 pb-1"
                    >
                      <span style="color: white; font-size: 16px;">Approved: </span><span
                        style="color: white; font-size: 16px;"
                        class="pl-2"
                      >{{ item.approved_by_name }}, {{ item.approved_at }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.shipped_by">
                    <v-col
                      class="pt-0 pb-1"
                    >
                      <span style="color: white; font-size: 16px;">Shipped: </span><span
                        style="color: white; font-size: 16px;"
                        class="pl-5"
                      >{{ item.shipped_by_name }}, {{ item.shipped_at }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.delivered_by">
                    <v-col
                      class="pt-0 pb-0"
                    >
                      <span style="color: white; font-size: 16px;">Delivered: </span><span
                        style="color: white; font-size: 16px;"
                        class="pl-3"
                      >{{ item.delivered_by_name }}, {{ item.delivered_at }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tooltip>
            </template>
            <template #[`item.truck_type_name`]="{ item }">
              <v-chip
                small
                :color="item && item.truck_type ? item.truck_type.color : 'grey'"
                text-color="white"
              >
                {{ item && item.truck_type ? item.truck_type.name : '' }}
              </v-chip>
            </template>
            <template #[`item.trucks`]="{ item }">
              <v-tooltip
                v-if="item && (item.status.id === statusIdApproved || item.status.id === statusIdOnTruck || item.status.id === statusIdDelivered) && item.trucks"
                top
                color="black"
              >
                <template #activator="{ on }">
                  <v-icon
                    v-on="on"
                  >
                    mdi-truck
                  </v-icon>
                </template>
                <v-card
                  class="pt-5 pb-5"
                  width="700"
                  elevation="0"
                  style="background-color: black;"
                >
                  <v-row
                    v-for="(truck, idx) in item.trucks"
                    :key="truck.id"
                  >
                    <v-col
                      class="pt-0 pb-0 pl-10"
                      cols="auto"
                    >
                      <span :style="'color: white; font-size: 16px;'">Truck {{ idx + 1 }}: </span>
                    </v-col>
                    <v-col
                      class="pt-0 pb-0"
                      cols="3"
                    >
                      <span :style="`color: white; font-size: 16px;`">{{ truck ? truck.truck_name : '' }}</span>
                    </v-col>
                    <v-col
                      class="pt-0 pb-0 pr-0"
                      cols="auto"
                    >
                      <span :style="`color: white; font-size: 16px;`">{{ item.date }},</span>
                    </v-col>
                    <v-col
                      class="pt-0 pb-0 pr-1 pl-1"
                      cols="auto"
                    >
                      <span :style="'color: white; font-size: 16px;'">{{ truck ? truck.start_time_formatted : '' }} -</span>
                    </v-col>
                    <v-col
                      class="pt-0 pb-0 pl-0 pr-1"
                      cols="auto"
                    >
                      <span :style="'color: white; font-size: 16px;'">{{ truck ? truck.end_time_formatted : '' }}</span>
                    </v-col>
                    <v-col
                      v-if="(item.status.id === statusIdOnTruck || item.status.id === statusIdDelivered) && truck.quantity"
                      class="pt-0 pb-0 pl-0"
                      cols="auto"
                    >
                      <span :style="'color: white; font-size: 16px;'">| Quantity: {{ truck ? truck.quantity : '' }} pcs.</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tooltip>
            </template>

            <template #[`item.actions`]="{ item }">
              <!-- <v-btn
                v-if="item.status_id === statusIdApproved && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdShipper))"
                v-show="!roles.includes(userRoleIdInstaller)"
                class="ml-1 mt-1 mb-1"
                color="success"
                outlined
                small
                @click="openShipForm(item.id)"
              >
                SHIP
              </v-btn> -->
              <!-- <v-btn
                v-if="item.status_id === statusIdOnTruck && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdShipper))"
                v-show="!roles.includes(userRoleIdInstaller)"
                class="ml-1 mt-1 mb-1"
                color="success"
                outlined
                small
                @click="deliver(item.id)"
              >
                DELIVER
              </v-btn>
              <v-btn
                v-if="item.status_id === statusIdApproved || item.status_id === statusIdOnTruck || item.status_id === statusIdDelivered "
                class="ml-1 mt-1 mb-1"
                color="purple"
                outlined
                small
                @click="openRequestViewForm(item.id)"
              >
                VIEW
              </v-btn>
              <v-btn
                v-if="item.status_id === statusIdPending && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdApprover))"
                v-show="!roles.includes(userRoleIdInstaller)"
                class="ml-1 mt-1 mb-1"
                color="success"
                outlined
                small
                @click="approve(item.id)"
              >
                APPROVE
              </v-btn>
              <v-btn
                v-if="item.status_id === statusIdPending && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdApprover))"
                v-show="!roles.includes(userRoleIdInstaller)"
                class="ml-1 mt-1 mb-1"
                color="purple"
                outlined
                small
                @click="openRejectForm(item.id)"
              >
                REJECT
              </v-btn> -->
              <!-- <v-tooltip
                v-if="item.status_id === statusIdRejected && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdRequester)) || item.create_by === user.id"
                v-show="!roles.includes(userRoleIdInstaller)"
                top
              >
                <template #activator="{ on }">
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="success"
                    outlined
                    small
                    v-on="on"
                    @click="submitForApproval(item.id)"
                  >
                    SUBMIT
                  </v-btn>
                </template>
                <span>SUBMIT FOR APPROVAL</span>
              </v-tooltip> -->

              <v-btn
                v-if="item.status_id !== statusIdOnTruck && item.status_id !== statusIdDelivered && (item.status_id === statusIdRejected || item.status_id === statusIdPending || (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdApprover)))"
                v-show="!roles.includes(userRoleIdInstaller)"
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openRequestEditForm(item)"
              >
                EDIT
              </v-btn>
              <v-btn
                v-if="item.status_id === statusIdOnTruck && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdShipper))"
                v-show="!roles.includes(userRoleIdInstaller)"
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="cancelShipping(item.id)"
              >
                CANCEL
              </v-btn>
              <v-btn
                v-if="item.status_id !== statusIdOnTruck && (item.status_id === statusIdRejected || item.status_id === statusIdPending || (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdApprover)))"
                v-show="!roles.includes(userRoleIdInstaller)"
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteRequest(item.id)"
              >
                DELETE
              </v-btn>
              <!-- <v-btn
                class="ml-1 mt-1 mb-1"
                color="orange"
                outlined
                small
                @click="openNoteForm(item.id)"
              >
                NOTES {{ item.notes ? `${item.notes.length > 0 ? '(' + item.notes.length + ')' : ''} ` : '' }}
              </v-btn> -->
              <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mt-1 mb-1 ml-1"
                    elevation="0"
                    fab
                    outlined
                    x-small
                    v-bind="attrs"
                    tile
                    color="primary"
                    style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                  class="pa-3"
                >
                  <v-list-item
                    v-if="item.status_id === statusIdApproved && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdShipper))"
                    v-show="!roles.includes(userRoleIdInstaller)"
                    @click="openShipForm(item.id)"
                  >
                    <v-list-item-title :style="`color: green; font-weight: 600;`">
                      <v-row align="center">
                        <v-col class="pt-4">
                          SHIP
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.status_id === statusIdOnTruck && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdShipper))"
                    v-show="!roles.includes(userRoleIdInstaller)"
                    @click="deliver(item.id)"
                  >
                    <v-list-item-title :style="`color: green; font-weight: 600;`">
                      <v-row align="center">
                        <v-col class="pt-4">
                          DELIVER
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.status_id === statusIdApproved || item.status_id === statusIdOnTruck || item.status_id === statusIdDelivered "
                    @click="openRequestViewForm(item.id)"
                  >
                    <v-list-item-title :style="`color: purple; font-weight: 600;`">
                      <v-row align="center">
                        <v-col class="pt-4">
                          VIEW
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.status_id === statusIdPending && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdApprover))"
                    v-show="!roles.includes(userRoleIdInstaller)"
                    @click="approve(item.id)"
                  >
                    <v-list-item-title :style="`color: green; font-weight: 600;`">
                      <v-row align="center">
                        <v-col class="pt-4">
                          APPROVE
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.status_id === statusIdPending && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdApprover))"
                    v-show="!roles.includes(userRoleIdInstaller)"
                    @click="openRejectForm(item.id)"
                  >
                    <v-list-item-title :style="`color: purple; font-weight: 600;`">
                      <v-row align="center">
                        <v-col class="pt-4">
                          REJECT
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.status_id === statusIdRejected && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdRequester)) || item.create_by === user.id"
                    v-show="!roles.includes(userRoleIdInstaller)"
                    @click="submitForApproval(item.id)"
                  >
                    <v-list-item-title :style="`color: green; font-weight: 600;`">
                      <v-row align="center">
                        <v-col class="pt-4">
                          <v-tooltip
                            top
                          >
                            <template #activator="{ on }">
                              <span v-on="on">SUBMIT</span>
                            </template>
                            <span>SUBMIT FOR APPROVAL</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="openNoteForm(item.id)"
                  >
                    <v-list-item-title :style="`color: orange; font-weight: 600;`">
                      <v-row align="center">
                        <v-col class="pt-4">
                          NOTES {{ item.notes ? `${item.notes.length > 0 ? '(' + item.notes.length + ')' : ''} ` : '' }}
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <truck-schedule-request-form
      ref="requestForm"
      @success="requestSuccess"
    />
    <truck-schedule-reject-form
      ref="rejectForm"
      @success="rejectSuccess"
    />
    <truck-schedule-approve-form
      ref="approveForm"
      @success="getRequests"
    />
    <truck-schedule-note-form
      ref="noteForm"
      @success="getRequests"
    />
    <truck-schedule-view-form ref="viewForm" />
    <truck-schedule-ship-form
      ref="shipForm"
      @success="getRequests"
    />
    <truck-schedule-cancel-form
      ref="cancelForm"
      @success="getRequests"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import TruckScheduleRequestForm from '@/components/forms/truckSchedules/TruckScheduleRequestForm';
import TruckScheduleRejectForm from '@/components/forms/truckSchedules/TruckScheduleRejectForm';
import TruckScheduleApproveForm from '@/components/forms/truckSchedules/TruckScheduleApproveForm';
import TruckScheduleNoteForm from '@/components/forms/truckSchedules/TruckScheduleNoteForm';
import TruckScheduleViewForm from '@/components/forms/truckSchedules/TruckScheduleViewForm';
import TruckScheduleShipForm from '@/components/forms/truckSchedules/TruckScheduleShipForm';
import TruckScheduleCancelForm from '@/components/forms/truckSchedules/TruckScheduleCancelForm';

export default {
  components: {
    TruckScheduleApproveForm,
    TruckScheduleRequestForm,
    TruckScheduleRejectForm,
    TruckScheduleNoteForm,
    TruckScheduleViewForm,
    TruckScheduleShipForm,
    TruckScheduleCancelForm,
  },
  props: {
    jobIdProp: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    requests: [],
    totalRequests: 0,
    statuses: [],
    filterStatusRequest: null,
    inventoryClassIdRequest: null,

    requestHeaders: [
      {
        text: 'Class', value: 'class', align: 'center',
      },
      {
        text: 'Status', value: 'status', align: 'center',
      },
      { text: 'Request#', value: 'id' },
      { text: 'Delivery Date', value: 'date', width: '10%' },
      { text: 'Job', value: 'job' },
      { text: 'Truck Type', value: 'truck_type_name', align: 'center' },
      {
        text: 'Trucks', value: 'trucks', align: 'center',
      },
      // { text: 'Created At', value: 'created_at_short', width: '7%' },
      { text: 'Users', value: 'created_by_name' },
      {
        text: 'Actions', value: 'actions', width: '20%', align: 'end',
      },
    ],
    requestFooterProps: {
      'items-per-page-options': [100, 500, 1000, 3000, 5000, -1],
    },
    requestLoading: false,
    statusIdNew: 1,
    statusIdPending: 2,
    statusIdApproved: 3,
    statusIdRejected: 4,
    statusIdOnTruck: 6,
    statusIdDelivered: 7,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdRequester: 15,
    userRoleIdApprover: 14,
    userRoleIdShipper: 16,
    userRoleIdInstaller: 17,
    jobs: [],
    filterJob: null,
    filterDate: null,
    dateMenu: false,
    filterTruckType: null,
    truckTypes: [],
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
      truckScheduleRequestState: (state) => state.truckScheduleRequestState,
    }),
    ...mapGetters([
    ]),
  },
  watch: {
    inventoryClassIdRequest() {
      this.getRequests();
      this.getJobs();
    },
    filterStatusRequest(value) {
      this.storeState('status_id', value)
      this.getRequests();
    },
    filterJob(value) {
      this.storeState('job_id', value)
      this.getRequests();
    },
    filterTruckType(value) {
      this.storeState('truck_type_id', value)
      this.getRequests();
    },
    filterDate(value) {
      this.$refs.menu.save(value);
      this.storeState('date', value)
      this.getRequests();
    },
  },
  created() {
    if(this.truckScheduleRequestState){
      if(this.truckScheduleRequestState.pagination) {this.pagination = this.truckScheduleRequestState.pagination}
      if(this.truckScheduleRequestState.job_id) {this.filterJob = this.truckScheduleRequestState.job_id}
      if(this.truckScheduleRequestState.status_id !== null) {this.filterStatusRequest = this.truckScheduleRequestState.status_id}
      if(this.truckScheduleRequestState.truck_type_id) {this.filterTruckType = this.truckScheduleRequestState.truck_type_id}
      if(this.truckScheduleRequestState.date) {this.filterDate = this.truckScheduleRequestState.date}
    }
    this.init();
  },
  methods: {
    init() {
      this.roles = this.user.roles.map((x) => x.id);
      if (this.jobIdProp) {
        this.filterJob = this.jobIdProp;
      }
      this.getRequests();
      this.getStatuses();
      this.getJobs();
      this.getTruckTypes();
    },

    storeState(property, value){
      const state = this.truckScheduleRequestState;
      state[property] = value;
      this.$store.commit('storeTruckScheduleRequestState', state);
    },

      updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { 
        this.pagination = pagination;
        this.storeState('pagination', pagination)
        }
    },

    async getRequests() {
      if (this.inventoryClassIdRequest === null) return;
      this.requestLoading = true;
      const submitModel = {
        filters: {
          status_id: this.filterStatusRequest,
          inventory_class_id: this.inventoryClassIdRequest,
          job_id: this.filterJob,
          date: this.filterDate,
          truck_type_id: this.filterTruckType,
        },
      };
      const res = await this.$api.truckScheduleRequest.readRequests(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.requestLoading = false;
      if (!res || !res.data || res.data.length === 0) {
        this.requests = [];
        this.totalRequests = 0;
        return;
      }
      this.requests = res.data;
      this.totalRequests = res.total;
    },

    async getStatuses() {
      this.statuses = await this.$api.truckScheduleStatus.get();
    },

    async getJobs() {
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId, is_sub_class: 1 },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.jobs = res;
    },
    async getTruckTypes() {
      const submitModel = {
        filters: {
        },
      };
      this.truckTypes = await this.$api.truckScheduleTruckType.read(submitModel);
    },

    async cancelApproval(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to Cancel this Request?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.truckScheduleRequest.get(`cancel-approval/${id}`);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getRequests();
    },

    async approve(id) {
      this.$refs.approveForm.open(id);
    },

    openRequestForm() {
      this.$refs.requestForm.open();
    },

    openRequestEditForm(item) {
      if (item.slot_id) {
        this.$refs.approveForm.open(item.id);
      } else {
        this.$refs.requestForm.open(item.id);
      }
    },

    async submitForApproval(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to submit this Request for Approval?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.truckScheduleRequest.get(`submit-for-approval/${id}`);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getRequests();
    },

    openRejectForm(id) {
      this.$refs.rejectForm.open(id);
    },

    async deleteRequest(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.truckScheduleRequest.delete(id);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getRequests();
    },

    openRequestViewForm(id) {
      this.$refs.viewForm.open(id);
    },

    requestSuccess() {
      this.getRequests();
    },

    rejectSuccess() {
      this.getRequests();
    },

    openNoteForm(id) {
      this.$refs.noteForm.open(id);
    },

    openShipForm(id) {
      this.$refs.shipForm.open(id);
    },

    cancelShipping(id) {
      this.$refs.cancelForm.open(id);
    },

    async deliver(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to Deliver this Request?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.truckScheduleRequest.get(`deliver/${id}`);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getRequests();
    },
  },
};
</script>

<style scoped>
span
{
  font-size: 12px;
}

::v-deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td
{
  padding-right: 4px !important;
  padding-left: 4px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th
{
  padding-right: 4px !important;
  padding-left: 4px !important;
}
</style>
