<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      scrollable
    >
      <v-form
        ref="form"
      >
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <span class="headline">Create New PO </span> <span
                  v-if="poNumber"
                  style="font-weight: bold; font-size: 24px;"
                >#{{ poNumber }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col
                cols="2"
              >
                <inventory-class-select-element
                  v-model="inventoryClassId"
                  :is-required="true"
                  :is-add-all="false"
                />
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="supplierId"
                  label="Supplier"
                  :items="suppliers"
                  :rules="[(v) => !!v || 'Supplier is required']"
                  :item-text="item => `${item.code} ${item.name}`"
                  item-value="id"
                  clearable
                  :single-select="false"
                >
                  <template #selection="{ item }">
                    <span>
                      <span class="font-weight-bold">{{ item.code }}</span> {{ item.name }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <span>
                      <span class="font-weight-bold">{{ item.code }}</span> {{ item.name }}
                    </span>
                  </template>
                  <template slot="label">
                    Supplier<RedAsterisk />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="jobId"
                  label="Job"
                  :items="jobs"
                  :item-text="item => `${item.code} ${item.name}`"
                  :rules="[(v) => !!v || 'Job is required']"
                  item-value="id"
                  clearable
                  :single-select="false"
                  :loading="jobsLoading"
                >
                  <template #selection="{ item }">
                    <span>
                      <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                    </span>
                  </template>

                  <template #item="{ item }">
                    <span>
                      <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                    </span>
                  </template>
                  <template slot="label">
                    Job<RedAsterisk />
                  </template>
                  <template #append-outer>
                    <v-btn
                      class="mrp-btn-add"
                      color="primary"
                      outlined
                      small
                      @click="openJobForm"
                    >
                      <v-icon small>
                        fas fa-plus
                      </v-icon>
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="text-end">
                <v-btn
                  color="primary"
                  outlined
                  :disabled="!jobId || !supplierId || !inventoryClassId"
                  @click="openGlassCodeForm"
                >
                  <v-icon
                    small
                    class="mr-1"
                  >
                    fas fa-plus
                  </v-icon>
                  New Glass Item
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-card
                  elevation="0"
                  outlined
                >
                  <v-data-table
                    :headers="headers"
                    :items="glassItems"
                    hide-default-footer
                    :items-per-page="-1"
                    dense
                    :expanded.sync="expandedItems"
                  >
                    <template #[`item.g_code`]="{ item }">
                      <span
                        style="font-weight: bold;"
                      >{{ item.g_code }}</span>
                    </template>
                    <template #[`item.width`]="{ item }">
                      <span>{{ item.width ? (uomSwitch ? convertToFraction(item.width) : item.width) : '-' }}</span>
                    </template>
                    <template #[`item.height`]="{ item }">
                      <span>{{ item.height ? (uomSwitch ? convertToFraction(item.height) : item.height) : '-' }}</span>
                    </template>
                    <template #[`item.status`]="{ item }">
                      <v-chip
                        v-if="item.glass_id"
                        small
                        color="orange"
                        text-color="white"
                      >
                        ACTIVE
                      </v-chip>
                      <v-chip
                        v-else
                        small
                        color="success"
                        text-color="white"
                      >
                        READY FOR IMPORT
                      </v-chip>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="purple"
                        outlined
                        small
                        @click="cloneGlassItem(item)"
                      >
                        Clone
                      </v-btn>
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        small
                        @click="editGlassItem(item)"
                      >
                        EDIT
                      </v-btn>
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="error"
                        outlined
                        small
                        @click="deleteGlassItem(item)"
                      >
                        DELETE
                      </v-btn>
                    </template>
                    <template #expanded-item="{ item }">
                      <td
                        v-if="item.glass"
                        :colspan="12"
                        class="pl-0 pr-0"
                      >
                        <v-container
                          fluid
                          :style="{backgroundColor: isDarkMode ? '#556B2F !important' : '#E8F5E9 !important'}"
                        >
                          <v-row>
                            <v-col
                              v-if="item.glass.part_number"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Part Number:</span> <span>{{ item.glass.part_number }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.awd_gas"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">AWD Gas:</span> <span>{{ item.glass.awd_gas }} - {{ getGlassDesctiption(item.glass.awd_gas) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.awd_spacer"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">AWD Spacer:</span> <span>{{ item.glass.awd_spacer }} - {{ getGlassDesctiption(item.glass.awd_spacer) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.cen_heat_treatment"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Cen Heat Treatment:</span> <span>{{ item.glass.cen_heat_treatment }} - {{ getGlassDesctiption(item.glass.cen_heat_treatment ) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.center_glass"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Center Glass:</span> <span>{{ item.glass.center_glass }} - {{ getGlassDesctiption(item.glass.center_glass) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.double_triple"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Double/Triple:</span> <span>{{ item.glass.double_triple }} - {{ getGlassDesctiption(item.glass.double_triple) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.ext_heat_treatment"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Ext Heat Treatment:</span> <span>{{ item.glass.ext_heat_treatment }} - {{ getGlassDesctiption(item.glass.ext_heat_treatment) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.exterior_glass"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Exterior Glass:</span> <span>{{ item.glass.exterior_glass }} - {{ getGlassDesctiption(item.glass.exterior_glass) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.fritted"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Fritted:</span> <span>{{ item.glass.fritted }} - {{ getGlassDesctiption(item.glass.fritted ) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.int_heat_treatment"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Int Heat Treatment:</span> <span>{{ item.glass.int_heat_treatment }} - {{ getGlassDesctiption(item.glass.int_heat_treatment) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.interior_glass"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Interior Glass:</span> <span>{{ item.glass.interior_glass }} - {{ getGlassDesctiption(item.glass.interior_glass) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.le_surface"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">LE Surface:</span> <span>{{ item.glass.le_surface }} - {{ getGlassDesctiption(item.glass.le_surface) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.middle_glass"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Middle Glass:</span> <span>{{ item.glass.middle_glass }} - {{ getGlassDesctiption(item.glass.middle_glass) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.sgl_heat_treatment"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Sgl Heat Treatment:</span> <span>{{ item.glass.sgl_heat_treatment }} - {{ getGlassDesctiption(item.glass.sgl_heat_treatment) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.single_glass"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Single Glass:</span> <span>{{ item.glass.single_glass }} - {{ getGlassDesctiption(item.glass.single_glass) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.spandrel_paint"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">Spandrel Paint:</span> <span>{{ item.glass.spandrel_paint }} - {{ getGlassDesctiption(item.glass.spandrel_paint) }}</span>
                            </v-col>
                            <v-col
                              v-if="item.glass.stc"
                              class="pr-0 pl-2"
                              cols="auto"
                            >
                              <span :style="{color: isDarkMode ? 'white' : 'rgba(0,0,0,0.6)'}">STC:</span> <span>{{ item.glass.stc }} - {{ getGlassDesctiption(item.glass.stc) }}</span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer />
              <v-col
                cols="auto"
                class="pb-0 pt-0"
              >
                <v-row align="center">
                  <v-col class="pr-2 pt-6">
                    <span :style="{color: isDarkMode ? '#ffffffb3' : '#00000099', fontSize: '16px !important'}">Decimal</span>
                  </v-col>
                  <v-col class="pl-0">
                    <v-switch
                      v-model="uomSwitch"
                      hide-details
                      class="mt-0 pt-3"
                      label="Fraction"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="auto"
                class="text-end"
              >
                <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '18px' }">Total Records: {{ glassItems.length }}</span>
              </v-col>
              <v-col
                cols="auto"
                class="text-end pr-10"
              >
                <span :style="{ color: isDarkMode ? '' : '#000000', fontWeight: 'bold', fontSize: '18px' }">Total Glass Item Qty: {{ glassItems.reduce((acc, obj) => acc + parseInt(obj.quantity, 10) , 0) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submit"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <job-form
      ref="jobForm"
      :is-supervisor="false"
      :is-client="false"
      :is-job-name="false"
      :is-address="false"
      @success="jobSuccess"
    />
    <glass-p-o-item-form
      ref="classPOItemForm"
      @success-po="glassPoItemSuccess"
    />
    <confirm-form ref="confirmForm" />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import JobForm from '@/components/forms/jobs/JobForm';
import GlassPOItemForm from '@/components/forms/glasses/GlassPOItemForm';

export default {
  components: {
    JobForm,
    GlassPOItemForm,
  },
  props: {

  },
  data: () => ({
    inventoryClassId: null,
    jobId: null,
    supplierId: 1,
    glassItems: [],
    jobs: [],
    suppliers: [],
    glassSpecifications: [],
    headers: [
      { text: 'Status', value: 'status', width: '10%', align: 'center' },
      { text: 'G Code', value: 'g_code' },
      { text: 'Glass Type', value: 'glass_type' },
      { text: 'Glass Thickness', value: 'glass_thickness' },
      { text: 'Tag#', value: 'tag_number' },
      { text: 'Width', value: 'width' },
      { text: 'Height', value: 'height' },
      { text: 'Price/SQFT', value: 'price' },
      { text: 'Qty', value: 'quantity' },
      { text: 'Note', value: 'holes' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    dialog: false,
    expandedItems: [],
    loading: false,
    uomSwitch: true,
    jobsLoading: false,
    poNumber: null,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    inventoryClassId() {
      this.getJobs();
    },
  },
  created() {
    // this.init();
  },
  methods: {

    init() {
      this.getJobs();
      this.getSuppliers();
      this.getGlasses();
    },

    async submit() {
      if (!await this.$refs.form.validate()) return;
      if (this.glassItems.length === 0) {
        this.$bus.$emit('showError', 'Please add at least one glass item.');
        return;
      }
      this.$bus.$emit('loading');
      const glssItems = this.glassItems.map((item) => {
        const newItem = { ...item };
        const job = this.jobs.find((j) => j.id === this.jobId);
        newItem.job_code = job ? job.code : null;
        newItem.supplier_id = this.supplierId;
        newItem.inventory_class_id = this.inventoryClassId;
        if (this.poNumber) newItem.po_number = this.poNumber;
        return newItem;
      });
      const res = await this.$api.glassPurchaseOrderItem.store(glssItems);

      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }
      this.$bus.$emit('loading');
      this.$emit('success');
      this.dialog = false;
    },

    async getJobs() {
      this.jobsLoading = true;
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId, is_manual: 1 },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.jobs = res;
      this.jobsLoading = false;
    },

    async getSuppliers() {
      this.suppliers = await this.$api.supplier.get();
    },

    async getGlasses() {
      this.glassSpecifications = await this.$api.glass.readSpecifications({ filters: { } });
    },

    getGlassDesctiption(code) {
      const glassSpecification = this.glassSpecifications.find((x) => x.code === code);
      if (!glassSpecification) return '';
      return glassSpecification.description;
    },

    async open(inventoryClassId, poNumber) {
      this.$bus.$emit('loading');
      this.poNumber = null;
      this.glassItems = [];
      if (this.$refs.form) this.$refs.form.reset();
      if (poNumber) {
        this.loading = true;
        const submitModel = {
          filters: {
            po_number: poNumber,
            inventory_class_id: inventoryClassId,
            is_not_include_with_batch: 1,
            is_group_remake: 1,
          },
        };
        const res = await this.$api.glass.readGlass(submitModel);
        const cancelCode = 3001;
        if (res === cancelCode) return;
        this.loading = false;
        if (!res || !res.data || res.data.length === 0) {
          this.glassItems = [];
          this.$bus.$emit('loading');
          return;
        }
        this.poNumber = poNumber;

        // all items have the same supplier, jobId and inventoryClassId
        this.supplierId = res.data[0].supplier_id;
        this.inventoryClassId = res.data[0].inventory_class_id;
        this.jobId = res.data[0].job ? res.data[0].job.id : null;
        this.expandedItems = res.data;
        this.glassItems = res.data;
      } else {
        this.supplierId = 1;
      }
      this.init();
      this.$bus.$emit('loading');
      this.dialog = true;
    },

    openGlassCodeForm() {
      this.$refs.classPOItemForm.open(null, this.supplierId);
    },

    editGlassItem(item) {
      this.$refs.classPOItemForm.open(item, this.supplierId);
    },

    async deleteGlassItem(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this Glass Item?',
      });
      if (!confirm) return;
      const index = this.glassItems.findIndex((x) => x.temp_id === item.temp_id);
      if (index >= 0) {
        this.glassItems.splice(index, 1);
      }
    },

    async openJobForm() {
      const res = await this.$api.job.get('generate-manual-code');
      if (!res) return;
      this.$refs.jobForm.open();
      this.$nextTick(() => {
        this.$refs.jobForm.item.code = res;
        this.$refs.jobForm.item.is_manual = 1;
      });
    },

    async jobSuccess() {
      await this.getJobs();
      const latestItemId = Math.max(...this.jobs.map((x) => x.id));
      if (latestItemId) { this.jobId = latestItemId; }
    },

    glassPoItemSuccess(item) {
      const index = this.glassItems.findIndex((x) => x.temp_id === item.temp_id);
      if (index >= 0) {
        this.glassItems.splice(index, 1, item);
      } else {
        this.glassItems.push(item);
      }
      const indexExpand = this.expandedItems.findIndex((x) => x === item.temp_id);
      if (indexExpand < 0) {
        this.expandedItems.push(item.temp_id);
      }
    },

    async cloneGlassItem(item) {
      const newItem = { ...item };
      newItem.temp_id = Date.now();
      this.glassItems.push(newItem);
    },

    convertToFraction(decimal) {
      // check if  a decimal number has numbers after the decimal point
      const decimalString = decimal.toString();
      const decimalPointIndex = decimalString.indexOf('.');

      if (decimalPointIndex === -1) {
        return decimal;
      }
      //

      const fractions = {
        0.0625: '1/16',
        0.125: '1/8',
        0.1875: '3/16',
        0.25: '1/4',
        0.3125: '5/16',
        0.375: '3/8',
        0.4375: '7/16',
        0.5: '1/2',
        0.5625: '9/16',
        0.625: '5/8',
        0.6875: '11/16',
        0.75: '3/4',
        0.8125: '13/16',
        0.875: '7/8',
        0.9375: '15/16',
      };

      // If the decimal is an exact match to any fraction, return the fraction directly
      if (decimal in fractions) {
        return fractions[decimal];
      }

      const wholeNumber = Math.floor(decimal);
      // eslint-disable-next-line no-param-reassign
      decimal -= wholeNumber; // Remove the whole number part

      let closestFraction = Object.keys(fractions)[0];

      // eslint-disable-next-line no-restricted-syntax
      for (const fraction of Object.keys(fractions)) {
        if (Math.abs(decimal - fraction) < Math.abs(decimal - closestFraction)) {
          closestFraction = fraction;
        }
      }

      if (wholeNumber > 0) {
        return `${wholeNumber} - ${fractions[closestFraction]}"`;
      }
      return fractions[closestFraction];
    },
  },
};
</script>
