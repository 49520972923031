<template class="formDialog">
  <v-row
    justify="center"
    style="font-size: 15px;"
  >
    <v-dialog
      v-model="dialog"
      max-width="1300px"
      :persistent="true"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card>
          <v-card-title>
            <span class="headline">New QR Code - <span
              style="font-size: 1.2em"
              class="font-weight-bold"
            >{{ qrCodeNumber }}</span></span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  md="3"
                  sm="2"
                  class="pb-0"
                >
                  <inventory-class-select-element
                    v-model="inventoryClassId"
                    :is-add-all="false"
                    :is-required="true"
                    :is-inst="false"
                    :is-parts="false"
                    :is-paint="false"
                  />
                </v-col>
                <v-col
                  md="6"
                  sm="8"
                >
                  <v-autocomplete
                    v-model="inventoryItemId"
                    :items="inventoryItems"
                    item-value="id"
                    :loading="loadingInventoryItem"
                    :item-text="
                      (item) => item.material_id + ': ' + item.name
                    "
                    :rules="[(v) => !!v || 'Inventory Item is required']"
                    no-data-text="Select a Class first"
                  >
                    <template slot="label">
                      Inventory Item<RedAsterisk />
                    </template>
                    <template #item="{ item }">
                      <v-chip
                        class="mt-1 mb-1 mr-3"
                        small
                        text-color="white"
                        style="cursor: pointer;"
                        :color="item && item.classes ? item.classes.color : 'white'"
                      >
                        {{ item && item.classes ? item.classes.name : '' }}
                      </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                    </template>
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #selection="{ item }">
                      <v-chip
                        class="mt-1 mb-1 mr-3"
                        small
                        text-color="white"
                        style="cursor: pointer;"
                        :color="item && item.classes ? item.classes.color : 'white'"
                      >
                        {{ item && item.classes ? item.classes.name : '' }}
                      </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="inventoryClassId !== inventoryClassIdGlass && inventoryClassId !== inventoryClassIdVWDGlass && inventoryClassId !== inventoryClassIdPanels && inventoryClassId !== inventoryClassIdSID && inventoryClassId !== inventoryClassIdPatioDoor && inventoryClassId !== inventoryClassIdEquipment"
                  md="3"
                  sm="2"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="length"
                    :items="lengths"
                    auto-select-first
                    :loading="loadingLength"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Length is required']"
                  >
                    <template slot="label">
                      Length<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-else-if="inventoryClassId === inventoryClassIdSID"
                  md="3"
                  sm="2"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="doorSizeId"
                    :items="doorSizes"
                    item-text="name"
                    item-value="id"
                    no-data-text="Select an Inventory Item first"
                    :rules="[(v) => !!v || 'Size is required']"
                  >
                    <template slot="label">
                      Size<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="uom"
                    :items="uoms"
                    auto-select-first
                    item-text="name"
                    item-value="id"
                    :loading="loadingUom"
                    :rules="[(v) => !!v || 'UOM is required']"
                  >
                    <template slot="label">
                      UOM<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="inventoryClassId !== inventoryClassIdGlass && inventoryClassId !== inventoryClassIdVWDGlass && inventoryClassId !== inventoryClassIdSID && inventoryClassId !== inventoryClassIdPatioDoor && inventoryClassId !== inventoryClassIdEquipment"
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="color"
                    :items="colorsList"
                    :loading="loadingColor"
                    item-text="code"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Color is required']"
                  >
                    <template #selection="{ item }">
                      <span>
                        {{ item.code }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span>
                        {{ item.code }}
                      </span>
                    </template>
                    <template slot="label">
                      Color<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="inventoryClassId === inventoryClassIdPatioDoor"
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="height"
                    :rules="[(v) => !!v || 'Door height is required']"
                  >
                    <template slot="label">
                      Door Height<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="inventoryClassId === inventoryClassIdPatioDoor"
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="width"
                    :rules="[(v) => !!v || 'Door width is required']"
                  >
                    <template slot="label">
                      Door Width<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="inventoryClassId === inventoryClassIdPatioDoor"
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="color"
                    :items="colorsList"
                    :loading="loadingColor"
                    item-text="code"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Interior Color is required']"
                  >
                    <template #selection="{ item }">
                      <span>
                        {{ item.code }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span>
                        {{ item.code }}
                      </span>
                    </template>
                    <template slot="label">
                      Interior Color<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="inventoryClassId === inventoryClassIdPatioDoor"
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="exColor"
                    :items="colorsList"
                    :loading="loadingColor"
                    item-text="code"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Exterior Color is required']"
                  >
                    <template #selection="{ item }">
                      <span>
                        {{ item.code }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span>
                        {{ item.code }}
                      </span>
                    </template>
                    <template slot="label">
                      Exterior Color<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="isShowLocation"
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="locationId"
                    :items="locationsList"
                    :loading="loadingLocation"
                    auto-select-first
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Location is required']"
                  >
                    <template slot="label">
                      Location<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="shelfId"
                    :items="inventoryLocations"
                    :loading="loadingShelf"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Shelf is required']"
                  >
                    <template slot="label">
                      Shelf<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="inventoryClassId === inventoryClassIdSID && inventoryItemId === inventoryIdSlab"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="doorHingeId"
                    :items="doorHinges"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Hinge is required']"
                  >
                    <template slot="label">
                      Hinge<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="inventoryClassId === inventoryClassIdSID && inventoryItemId === inventoryIdSlab"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="doorSwingId"
                    :items="doorSwings"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Swing is required']"
                  >
                    <template slot="label">
                      Swing<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="inventoryClassId === inventoryClassIdSID && inventoryItemId === inventoryIdInsert"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="doorLiteId"
                    :items="doorLites"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Lite is required']"
                  >
                    <template slot="label">
                      Lite<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="isShowPO"
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="po_number"
                    :items="inventoryPos"
                    :clearable="true"
                    label="PO#"
                    :rules="[(v) => !!v || 'PO# is required']"
                  >
                    <template slot="label">
                      PO#<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="3"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="quantity"
                    :rules="[(v) => !!v || 'Quantity is required']"
                    type="number"
                  >
                    <template slot="label">
                      Quantity<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-file-input
                    v-model="supplierLabel"
                    accept="image/*"
                    capture="user"
                    label="Supplier Label"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Supplier Label Photo is required']"
                  >
                    <template slot="label">
                      Supplier Label<RedAsterisk />
                    </template>
                  </v-file-input>
                </v-col>
                <v-col
                  v-if="inventoryClassId === inventoryClassIdGlass || inventoryClassId === inventoryClassIdVWDGlass"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="batchNumber"
                    :rules="[(v) => !!v || 'Vendor Batch# is required']"
                  >
                    <template slot="label">
                      Vendor Batch#<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="inventoryClassId === inventoryClassIdGlass || inventoryClassId === inventoryClassIdVWDGlass"
                  md="3"
                  sm="6"
                  class="pb-0"
                >
                  <v-menu
                    ref="menu"
                    v-model="dateMenu"
                    :return-value.sync="productionDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="productionDate"
                        label="Date"
                        readonly
                        v-bind="attrs"
                        :rules="[(v) => !!v || ' Production Date is required']"
                        v-on="on"
                        @change="$refs.menu.save(productionDate)"
                      >
                        <template slot="label">
                          Production Date<RedAsterisk />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="productionDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="productionDate = null"
                      >
                        Clear
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  v-if="inventoryClassId !== inventoryClassIdPatioDoor && inventoryClassId !== inventoryClassIdEquipment"
                  md="3"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="projectId"
                    :items="projects"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    label="Project"
                  />
                </v-col>
              </v-row>
              <v-row v-if="inventoryClassId === inventoryClassIdSID">
                <v-col class="pt-0">
                  <v-radio-group
                    v-model="isPainted"
                    hide-details
                  >
                    <template #label>
                      <span style="font-size: 1.2em; color: rgba(0, 0, 0, 0.65);">Paint</span>
                    </template>
                    <v-row>
                      <v-col cols="auto">
                        <v-radio
                          label="PAINTED"
                          :value="1"
                        />
                      </v-col>
                      <v-col cols="auto">
                        <v-radio
                          label="NON-PAINTED"
                          :value="0"
                        />
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row
                v-if="inventoryClassId === inventoryClassIdPatioDoor"
              >
                <v-col
                  cols="6"
                  class="pa-3"
                >
                  <v-radio-group
                    v-model="doorScreenId"
                    :rules="[(v) => !!v || 'Door screen selection is required']"
                  >
                    <template #label>
                      <span style="font-size: 1.2em; color: rgba(0, 0, 0, 0.65);">Screen<RedAsterisk /></span>
                    </template>
                    <v-row>
                      <v-col cols="auto">
                        <v-radio
                          label="WITH SCREEN"
                          :value="1"
                        />
                      </v-col>
                      <v-col cols="auto">
                        <v-radio
                          label="WITHOUT SCREEN"
                          :value="2"
                        />
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="6"
                  class="pa-3"
                >
                  <v-radio-group
                    v-model="isFritted"
                    :rules="[(v) => (v !== null && v !== undefined && v !== '') || 'Fritted or Non-Fritted selection is required']"
                  >
                    <template #label>
                      <span style="font-size: 1.2em; color: rgba(0, 0, 0, 0.65);">Fritted<RedAsterisk /></span>
                    </template>
                    <v-row>
                      <v-col cols="auto">
                        <v-radio
                          label="FRITTED"
                          :value="1"
                        />
                      </v-col>
                      <v-col cols="auto">
                        <v-radio
                          label="NON-FRITTED"
                          :value="0"
                        />
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="inventoryClassId === inventoryClassIdSID && isPainted == 1">
                <v-col
                  md="3"
                  sm="6"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="color"
                    :items="colorsList"
                    :loading="loadingColor"
                    item-text="code"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || `${inventoryItemId !== inventoryIdSweep ? 'Interior Color' : 'Color'} is required`]"
                  >
                    <template #selection="{ item }">
                      <span>
                        {{ item.code }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span>
                        {{ item.code }}
                      </span>
                    </template>
                    <template slot="label">
                      {{ inventoryItemId !== inventoryIdSweep ? 'Interior Color' : 'Color' }}<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="inventoryItemId !== inventoryIdSweep"
                  md="3"
                  sm="6"
                  class="pb-0 pt-0"
                >
                  <v-autocomplete
                    v-model="exColor"
                    :items="colorsList"
                    :loading="loadingColor"
                    item-text="code"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Exterior Color is required']"
                  >
                    <template #selection="{ item }">
                      <span>
                        {{ item.code }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span>
                        {{ item.code }}
                      </span>
                    </template>
                    <template slot="label">
                      Exterior Color<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="12"
                  sm="12"
                >
                  <v-row>
                    <v-col
                      class="pb-0"
                    >
                      <span style="font-size: 18px;">Note</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      class="pt-0"
                    >
                      <v-textarea
                        v-model="noteText"
                        outlined
                        rows="3"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              large
              color="normal"
              class="mr-2"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              large
              color="primary"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  components: { },
  props: {
    enteredTag: {
      type: String,
      default: '',
    },
    isShowPO: {
      type: Boolean,
      default: false,
    },
    isShowLocation: {
      type: Boolean,
      default: false,
    },
    defaultLocation: {
      type: Object,
      default: () => {},
    },
    defaultShelf: {
      type: Object,
      default: () => {},
    },
    defaultQuantity: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    // Inventory Item
    uom: null,
    length: null,
    color: null,
    inventoryTag: null,
    inventoryItemId: null,
    shelfId: null,
    quantity: null,
    userId: null,
    po_number: null,
    locationId: null,
    isQrEqualsTag: 0,
    qrCodeNumber: '',
    inventoryClassId: null,
    supplierLabel: null,
    batchNumber: null,
    doorSizeId: null,
    doorHingeId: null,
    doorSwingId: null,
    doorLiteId: null,
    height: null,
    width: null,
    doorScreenId: 1,
    isFritted: 1,
    isPainted: 0,
    exColor: null,
    projectId: null,
    productionDate: new Date().toISOString().slice(0, 10),
    inventoryClassId4500: 1,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdPanels: 8,
    inventoryClassIdSID: 9,
    inventoryClassIdPanelParts: 10,
    inventoryClassIdEquipment: 13,
    inventoryClassIdPatioDoor: 14,

    inventoryIdSlab: 1100,
    inventoryIdSweep: 1101,
    inventoryIdInsert: 1102,
    noteText: null,

    loadingInventoryItem: false,
    loadingLength: false,
    loadingUom: false,
    loadingColor: false,
    loadingLocation: false,
    loadingShelf: false,
    // Inventory
    lengths: [],
    uoms: [],
    colorsList: [],
    inventoryItems: [],
    inventoryLocations: [],
    locationsList: [],
    doorHinges: [],
    doorSwings: [],
    doorLites: [],
    doorSizes: [],
    projects: [],
    inventoryPos: [],

    // Form
    dialog: false,
    dialogTitle: '',
    valid: false,
    btnSubmitName: 'ADD',
    dateMenu: false,

  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      user: 'user',
    }),
  },
  watch: {
    enteredTag(value) {
      if (this.isQrEqualsTag) { this.inventoryTag = value; }

      this.qrCodeNumber = value;
    },
    inventoryClassId() {
      this.$refs.form.reset();
      this.fetchInventoryItems();
    },
    inventoryItemId(val) {
      if (val === this.inventoryIdSlab || val === this.inventoryIdSweep || val === this.inventoryIdInsert) {
        this.getDoorSizes();
      }
      this.getUoms();
      this.getLengths();
      this.getInventoryPos();
    },
    locationId(value) {
      if (!value) return;
      this.fetchInventoryLocations();
    },
    productionDate(val) {
      this.$refs.menu.save(val);
    },
  },
  created() {
    const QrEqualsTagBRName = 'QR Code Equals Inv. Tag';
    this.isQrEqualsTag = this.businessRulesGetBoolean(QrEqualsTagBRName);

    this.initialize();
  },
  methods: {
    async initialize() {
      this.getUoms();
      this.fetchColors();
      this.getLengths();
      this.fetchInventoryItems();
      this.fetchLocations();
      this.fetchInventoryLocations();
      this.getDoorHinges();
      this.getDoorSwings();
      this.getDoorLites();
      this.getProjects();
      this.getInventoryPos();

      if (!this.user) return;
      this.userId = this.user.id;
    },
    async getUoms() {
      this.loadingUom = true;
      const submitData = {
        filters:
        {
          inventory_item_id: this.inventoryItemId,
        },
      };
      this.uoms = await this.$api.uom.read(submitData);
      this.loadingUom = false;
    },
    async fetchColors() {
      this.loadingColor = true;
      const submitModel = {
        filters: {
        },
      };

      this.colorsList = await this.$api.color.read(submitModel);
      this.loadingColor = false;
    },
    async getLengths() {
      this.loadingLength = true;
      const submitData = {
        filters:
        {
          inventory_item_id: this.inventoryItemId,
          inventory_class_id: this.inventoryClassId,
        },
      };
      this.lengths = await this.$api.length.read(submitData);
      this.loadingLength = false;
    },
    async fetchInventoryItems() {
      if (!this.inventoryClassId) return;
      this.loadingInventoryItem = true;
      this.inventoryItems = [];
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };

      const res = await this.$api.inventoryItem.readData(readModel);
      this.loadingInventoryItem = false;
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        return;
      }
      this.inventoryItems = res.data;
    },
    async fetchInventoryLocations() {
      this.loadingShelf = true;
      const readModel = {
        filters:
        {
          location_id: this.locationId,
        },
      };
      this.inventoryLocations = await this.$api.inventoryLocation.read(readModel);
      this.loadingShelf = false;
    },

    async fetchLocations() {
      this.loadingLocation = true;
      this.locationsList = await this.$api.location.getList();
      this.loadingLocation = false;
    },

    async getDoorHinges() {
      this.doorHinges = await this.$api.doorHinge.getList();
    },

    async getDoorSwings() {
      this.doorSwings = await this.$api.doorSwing.getList();
    },

    async getDoorLites() {
      this.doorLites = await this.$api.doorLite.getList();
    },

    async getDoorSizes() {
      this.doorSizes = await this.$api.doorSize.get(`${this.inventoryItemId}`);
    },

    async getProjects() {
      const submitModel = {
        filters: {

        },
      };
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        return;
      }
      this.projects = res.data;
    },

    async getInventoryPos() {
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassId,
        },
      };
      const res = await this.$api.inventoryPo.read(submitModel, null, 'read-simple');
      if (!res) {
        return;
      }
      this.inventoryPos = ['Not Applicable', ...res];
    },

    async submitForm() {
      if (this.$refs.form.validate()) {
        this.$bus.$emit('loading');
        const data = new FormData();

        // Qr Code is treated as inv tag for now
        if (this.qrCodeNumber) {
          data.append('inventory_tag', this.qrCodeNumber);
        }

        if (this.inventoryItemId) {
          data.append('inventory_item_id', this.inventoryItemId);
        }

        if (this.uom) {
          data.append('uom_id', this.uom);
        }

        if (this.length) {
          data.append('length_id', this.length);
        }

        if (this.color) {
          data.append('color_id', this.color);
        }

        if (this.shelfId) {
          data.append('inventory_location_id', this.shelfId);
        }

        if (this.quantity) {
          data.append('quantity', this.quantity);
        }
        if (this.user.id) {
          data.append('created_by', this.user.id);
        }

        if (this.po_number) {
          data.append('po_number', this.po_number);
        }

        if (this.locationId) {
          data.append('location_id', this.locationId);
        }

        if (this.supplierLabel) {
          data.append('supplier_label', this.supplierLabel);
        }

        if (this.batchNumber) {
          data.append('batch_number', this.batchNumber);
        }

        if (this.productionDate) {
          data.append('production_date', this.productionDate);
        }

        if (this.doorHingeId) {
          data.append('door_hinge_id', this.doorHingeId);
        }

        if (this.doorSwingId) {
          data.append('door_swing_id', this.doorSwingId);
        }

        if (this.doorLiteId) {
          data.append('door_lite_id', this.doorLiteId);
        }

        if (this.doorSizeId) {
          data.append('door_size_id', this.doorSizeId);
        }

        if (this.isPainted) {
          data.append('is_painted', this.isPainted);
        }

        if (this.exColor) {
          data.append('ex_color_id', this.exColor);
        }

        if (this.noteText) {
          data.append('note_text', this.noteText);
        }

        if (this.projectId) {
          data.append('project_id', this.projectId);
        }

        if (this.height) {
          data.append('height', this.height);
        }

        if (this.width) {
          data.append('width', this.width);
        }

        if (this.isFritted !== undefined && this.isFritted !== null) {
          data.append('is_fritted', this.isFritted);
        }

        if (this.doorScreenId) {
          data.append('door_screen_id', this.doorScreenId);
        }

        const res = await this.$api.inventoryTag.addTag(data);
        this.$bus.$emit('loading-stop');
        if (res === true) {
          this.$emit('submitQRCodeTag', 'QR Code has been added to ERP');

          this.closeDialog();
        } else {
          this.$bus.$emit('showError', res);
        }
      }
    },

    async openDialog() {
      if (this.$refs.form) await this.$refs.form.reset();
      this.dialog = true;
      if (this.isQrEqualsTag) { this.inventoryTag = this.enteredTag; }

      this.qrCodeNumber = this.enteredTag;

      // set default values

      // UOM
      if (this.uoms.length) {
        const uomIdPcs = 1;
        const uom = this.uoms.find((x) => x.id === uomIdPcs);
        if (uom) { this.uom = uom.id; } else { this.uom = 0; }
      } else { this.uom = 0; }

      // Length
      if (this.lengths.length) {
        const length = this.lengths.find((x) => x.name === '16 ft.');
        if (length && length.id) { this.length = length.id; } else { this.length = 0; }
      } else { this.length = 0; }

      // Location
      // If is set prop defaultLocation
      // else get first record from the list
      if (this.defaultLocation) {
        this.locationId = this.defaultLocation.id;
      } else if (this.locationsList.length && this.locationsList[0].id) {
        const locationId = this.locationsList[0].id;
        if (locationId) { this.locationId = locationId; } else { this.locationId = 0; }
      } else { this.locationId = 0; }

      // Shelf
      // If is set prop defaultShelf
      if (this.defaultShelf) {
        this.shelfId = this.defaultShelf.id;
      }

      // Quantity
      // If is set prop defaulQuantity
      if (this.defaultQuantity) {
        this.quantity = this.defaultQuantity;
      }
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.mrp-btn-add {
  min-width: 35px !important;
  padding: 0 !important;
}
.v-text-field >>> input {
    font-size: 1.5em !important;
}
.v-autocomplete >>> input{
    font-size: 1.5em !important;
}
.v-autocomplete >>> label{
    font-size: 1.1em !important;
}
.v-text-field >>> label {
    font-size: 1.1em !important;
}
.v-btn {
    font-size: 1.1em !important;
}
/* v-autocomplete highlight color */
.theme--light.v-list-item .v-list-item__mask {
    background: #e6e90a !important;
}
</style>
