<template>
  <v-snackbar
    v-model="snackbar"
    :bottom="y === 'bottom'"
    :color="color"
    :left="x === 'left'"
    :multi-line="mode === 'multi-line'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'"
    :centered="y === 'centered'"
    :vertical="mode === 'vertical'"
    class="pt-0"
  >
    {{ text }}
    <template #action="{ attrs }">
      <v-btn
        dark
        text
        v-bind="attrs"
        @click="snackbar = false"
        @click.prevent
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data: () => ({
    color: '',
    mode: '',
    snackbar: false,
    text: '',
    timeout: 6000,
    x: null,
    y: 'top',
  }),
  mounted() {
    this.$bus.$on('showSuccess', this.showSuccess);
    this.$bus.$on('showError', this.showError);
  },
  methods: {
    showSuccess(text = null, position = null) {
      if (this.snackbar === true) return;
      if (text === '' || text === null) {
        this.text = 'Success';
      } else {
        this.text = text;
      }
      switch(position) {
        case 'centered':
          this.y = 'centered';
          break;
        case 'bottom':
          this.y = 'centered';
          break;
        default:
          this.y = 'top'
      }

      this.color = 'green';
      this.snackbar = true;
    },
    showError(text = null, position = null) {
      if (this.snackbar === true) return;
      if (text === '' || text === null) {
        this.text = 'Whoops, something went wrong... Unexpected Error.';
      } else {
        this.text = text;
      }
      switch(position) {
        case 'centered':
          this.y = 'centered';
          break;
        case 'bottom':
          this.y = 'centered';
          break;
        default:
          this.y = 'top'
      }

      this.color = 'red';
      this.snackbar = true;
    },
  },
};
</script>
