<template>
  <v-container
    v-if="inventoryTag"
    fluid
    class="ma-3"
  >
    <v-row
      class="pr-6"
    >
      <v-col
        cols="4"
        class="align-center pb-0"
        style="justify-content: center; display: flex;"
      >
        <v-img
          :src="convertedPhoto(inventoryTag.item.photo, inventoryTag.item.mime_type)"
          contain
          height="100%"
          width="100%"
          class="profile-photo"
        />
      </v-col>
      <v-col
        cols="8"
        class="pb-0"
      >
        <v-row
          style=""
          class="align-center pt-1 pb-1"
        >
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="4">
                <span style="font-size: 30px; font-weight: 500;">QR Code: </span>
              </v-col>
              <v-col>
                <span style="font-size: 30px; font-weight: 600;">{{ inventoryTag.inventory_tag }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="4">
                <span style="font-size: 30px; font-weight: 500;">Class:</span>
              </v-col>
              <v-col>
                <v-chip
                  class="mt-1 mb-1"
                  text-color="white"
                  :color="inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.color : 'white'"
                >
                  <span style="font-size: 20px; color: #fff !important;">{{ inventoryTag && inventoryTag.item && inventoryTag.item.classes ? inventoryTag.item.classes.name : '-' }}</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          style="background-color: #F5F5F5; border-radius: 6px;"
          class="align-center pt-1 pb-1"
        >
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="4">
                <span class="desc-span">Inventory Item:</span>
              </v-col>
              <v-col>
                <span class="main-span">{{ inventoryTag ? inventoryTag.item_full_name : '' }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="4">
                <span class="desc-span">Color:</span>
              </v-col>
              <v-col>
                <span class="main-span">{{ inventoryTag ? inventoryTag.color_code : '' }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          style=""
          class="align-center pt-1 pb-1"
        >
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="4">
                <span class="desc-span">Length:</span>
              </v-col>
              <v-col>
                <span class="main-span">{{ inventoryTag ? inventoryTag.length_name : '' }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="4">
                <span class="desc-span">UOM:</span>
              </v-col>
              <v-col>
                <span class="main-span">{{ inventoryTag ? inventoryTag.uom_name : '' }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          style="background-color: #F5F5F5; border-radius: 6px;"
          class="align-center pt-1 pb-1"
        >
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="4">
                <span class="desc-span">Location:</span>
              </v-col>
              <v-col>
                <span class="main-span">{{ inventoryTag ? inventoryTag.location_name : '' }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="4">
                <span class="desc-span">Shelf:</span>
              </v-col>
              <v-col>
                <span class="main-span">{{ inventoryTag ? inventoryTag.inventory_location_name : '' }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pr-6">
      <v-col cols="4">
        <v-row class="align-center text-center">
          <v-col>
            <span style="font-size: 42px; font-weight: 500;">Qty On Hand: </span><span style="font-size: 42px; font-weight: 600;">{{ inventoryTag.quantity_on_hand }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-form
          ref="form"
        >
          <v-row>
            <v-col class="pl-0 pr-0 pb-6">
              <v-radio-group
                v-model="recordTypeId"
                hide-details
                dense
                row
              >
                <v-radio
                  :value="0"
                  small
                  class="mr-10"
                >
                  <template slot="label">
                    <v-chip
                      class="mt-1 mb-1"
                      text-color="white"
                      color="#BF0000"
                    >
                      <span style="font-size: 20px; color: #fff !important;">Supply Record</span>
                    </v-chip>
                  </template>
                </v-radio>
                <v-radio
                  :value="1"
                  small
                  class="mr-10"
                >
                  <template slot="label">
                    <v-chip
                      class="mt-1 mb-1"
                      text-color="white"
                      color="#3BA424"
                    >
                      <span style="font-size: 20px; color: #fff !important;">Return Record</span>
                    </v-chip>
                  </template>
                </v-radio>
                <v-radio
                  :value="2"
                  small
                >
                  <template slot="label">
                    <v-chip
                      class="mt-1 mb-1"
                      text-color="white"
                      color="#FF8C00"
                    >
                      <span style="font-size: 20px; color: #fff !important;">Scrap Record</span>
                    </v-chip>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="recordTypeId == 2">
            <v-col class="pl-0 pr-0 pt-0 pb-0">
              <v-select
                v-model="form.scrap_reason_id"
                :items="scrapReasons"
                item-text="name"
                item-value="id"
                filled
                clearable
                :rules="[(v) => !!v || 'Reason is required']"
              >
                <template slot="label">
                  Reason<RedAsterisk />
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pl-0 pr-0 pt-0 pb-0">
              <v-autocomplete
                v-model="form.job_id"
                label="Job"
                :items="jobs"
                :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
                item-value="id"
                clearable
                :rules="[(v) => !!v || 'Job is required']"
                filled
                :loading="loadingJobs"
              >
                <template #selection="{ item }">
                  <span>
                    <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                  </span>
                </template>

                <template #item="{ item }">
                  <span>
                    <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                  </span>
                </template>
                <template slot="label">
                  Job<RedAsterisk />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pl-0 pr-0 pt-0 pb-0">
              <v-text-field
                v-model="form.quantity_supplied"
                :rules="[(v) => !!v || 'Quantity is required']"
                type="number"
                filled
              >
                <template slot="label">
                  Quantity<RedAsterisk />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row class="mr-1">
      <v-spacer />
      <v-col cols="auto">
        <v-btn
          color="normal"
          class="mr-3"
          x-large
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          x-large
          :loading="loadingSubmit"
          @click="submit"
        >
          SUBMIT
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    qrCode: null,
    topErrorMessage: null,
    inventoryTag: null,
    jobs: [],
    form: {
      quantity_supplied: null,
      quantity_missed: null,
      is_return: 0,
      is_scrap: 0,
      scrap_reason_id: null,
      job_id: null,
    },
    recordTypeId: 0,
    loadingJobs: false,
    scrapReasons: [],
    loadingSubmit: false,
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {
    recordTypeId() {
      if (this.recordTypeId === 2) {
        this.form.scrap_reason_id = null;
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.qrCode = this.$route.params.code;

      this.getInventoryTag();
    },

    async getInventoryTag() {
      const submitModel = {
        filters: {
          inventory_tag: this.qrCode,
          is_qty_on_hand_o: 0,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);

      if (res && res.status === 505) {
        this.topErrorMessage = res.message;
        return;
      }

      if (!res || !res.data || !res.data[0]) {
        this.inventoryTag = null;
        return;
      }

      const firstIndex = 0;
      this.inventoryTag = res.data[firstIndex];

      this.getJobs();
      this.getScrapReasons();
      console.log(this.inventoryTag);
    },

    async getJobs() {
      this.loadingJobs = true;
      const readModel = {
        filters:
        {
          // inventory_class_id: this.inventoryTag.item.inventory_class_id,
          is_manual: 1,
        },
        sort: {
          order_by_first: 'id',
          direction_sort: 0,
        },
      };
      this.jobs = await this.$api.job.read(readModel);
      this.loadingJobs = false;
    },

    async getScrapReasons() {
      this.scrapReasons = await this.$api.supplyToLineScrapReason.get();
    },

    async submit() {
      if (!await this.$refs.form.validate()) return;
      this.loadingSubmit = true;

      const submitModel = {
        job_id: this.form.job_id,
        quantity_supplied: this.form.quantity_supplied,
        quantity_missed: 0,
        is_return: this.recordTypeId === 1 ? 1 : 0,
        is_scrap: this.recordTypeId === 2 ? 1 : 0,
        inventory_item_id: this.inventoryTag.item.id,
        inventory_tag: this.inventoryTag.inventory_tag,
        scrap_reason_id: this.form.scrap_reason_id,
        inventory_class_id: this.inventoryTag.item.inventory_class_id,
      };

      const res = await this.$api.supplyToLineRecord.store(submitModel);
      this.loadingSubmit = false;

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.$router.push('/stl-qr');
    },

    convertedPhoto(photo, mimeType) {
      if (!photo) return '';
      return `data:${mimeType};base64, ${photo}`;
    },

    cancel() {
      this.$router.push('/stl-qr');
    },

  },
};
</script>

<style scoped>
.desc-span {
  font-size: 16px;
  color: rgba(0,0,0,0.6);
  font-weight: 500;
}
.main-span {
  font-size: 16px;
  font-weight: 600;
}

.profile-photo{
  max-width: 350px;
  max-height: 350px;
}
</style>
