<template>
  <v-container fluid>
    <v-row align="center">
      <v-col class="pb-0 pt-0">
        <v-tabs v-model="selectedTab">
          <v-tab> PROJECTS </v-tab>
          <v-tab> PROJECTS SCOPE </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-if="selectedTab === 0">
      <v-col class="pt-0">
        <v-row>
          <v-spacer />
          <v-col class="text-end">
            <v-btn
              color="primary"
              outlined
              @click="open"
            >
              <v-icon
                small
                class="mr-1"
              >
                fas fa-plus
              </v-icon>
              New Project
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-card
              elevation="0"
              outlined
              style="height: calc(100% + 24px)"
            >
              <!-- <v-data-table
                :headers="headers"
                :items="items"
                :footer-props="footer"
                :items-per-page="pagination.itemsPerPage"
                :search="search"
                :loading="loading"
                dense
                fixed-header
                :height="windowHeight"
                :server-items-length="total"
                @pagination="updatePagination"
              >
              <template #[`item.class`]="{ item }">
                <v-chip
                  v-if="item.class"
                  small
                  :color="item && item.class ? item.class.color : 'grey'"
                  text-color="white"
                >
                  {{ item && item.class ? item.class.name : '' }}
                </v-chip>
              </template>
              <template #[`item.name`]="{ item }">
                <text-highlight :queries="search" style="font-weight: bold">
                  {{ item.name }}
                </text-highlight>
              </template>
              <template #[`item.client`]="{ item }">
                <text-highlight :queries="search">
                  {{ item.client ? item.client.name : '' }}
                </text-highlight>
              </template>
              <template #[`item.address`]="{ item }">
                <v-tooltip top color="black">
                  <template #activator="{ on }">
                    <span v-on="on">{{
                      item.address_simple ? item.address_simple : ''
                    }}</span>
                  </template>

                  <text-highlight :queries="search">
                    {{ item.address_full ? item.address_full : '' }}
                  </text-highlight>
                </v-tooltip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  class="ml-1 mt-1 mb-1"
                  color="primary"
                  outlined
                  small
                  @click="openEdit(item.id)"
                >
                  EDIT
                </v-btn>
                <v-btn
                  class="ml-1 mt-1 mb-1 mr-1"
                  color="error"
                  outlined
                  small
                  @click="softDelete(item.id)"
                >
                  DELETE
                </v-btn>
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="mt-1 mb-1"
                      elevation="0"
                      fab
                      outlined
                      x-small
                      v-bind="attrs"
                      tile
                      color="primary"
                      style="
                        border-radius: 7px;
                        width: 28px !important;
                        height: 28px !important;
                      "
                      v-on="on"
                    >
                      <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pa-3">
                    <v-list-item @click="redirectScope(item)">
                      <v-list-item-title
                        :style="`color: green; font-weight: 600;`"
                      >
                        <v-row align="center">
                          <v-col class="pt-4"> PROJECT SCOPES </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              </v-data-table> -->
              <prime-data-table
                :table="table"
                :headers="headers"
                :default-headers="defaultHeaders"
                :data="items"
                :search-highlight="search"
                :loading="loading"
                :pagination="pagination"
                :rows-per-page-options="rowsPerPageItems"
                :total-records="total"
                @paginationUpdated="updatePagination"
                @syncHeadersOrder="syncHeadersOrder"
                @resetHeaders="resetHeaders"
                @openEdit="openEdit"
                @softDelete="softDelete"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="selectedTab === 1">
      <v-col class="pt-0">
        <scope-of-project-view />
      </v-col>
    </v-row>
    <project-form
      ref="projectForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import ProjectForm from '@/components/forms/projects/ProjectForm';
import ScopeOfProjectView from '@/views/ScopeOfProjects/ScopeOfProjectView';
import { mapState } from 'vuex';
import PrimeDataTable from '@/components/PrimeDataTable/PrimeDataTable';

export default {
  components: {
    ProjectForm,
    ScopeOfProjectView,
    PrimeDataTable,
  },
  data: () => ({
    items: [],
    // headers: [
    //   { text: 'Class', value: 'class', width: '3%' },
    //   { text: 'Project Name', value: 'name', width: '20%' },
    //   { text: 'Client', value: 'client', width: '22%' },
    //   { text: 'Job Address', value: 'address', width: '15%' },
    //   { text: 'Created At', value: 'created_at_short', width: '10%' },
    //   { text: 'Created By', value: 'user_full_name', width: '10%' },
    //   {
    //     text: 'Actions',
    //     value: 'actions',
    //     align: 'end',
    //     width: '20%',
    //   },
    // ],
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    rowsPerPageItems: [10, 20, 50, 100, 500, 1000],
    loading: false,
    topSpaceHeight: 235,
    windowHeight: 1000,
    pagination: {
      itemsPerPage: 10,
      page: 1,
    },
    total: 0,
    totalRecords: 0,
    selectedTab: 0,
    table: 'projectView',
    headers: [],
    defaultHeaders: [
      {
        text: 'Class',
        value: 'class',
        sortable: false,
        type: 'chip',
        // chipColor: 'AccountTypeColor',
        class: 'centered-column',
        headerStyle: { width: '5%' },
      },
      {
        text: 'Project Name',
        value: 'name', // col.value
        sortable: false,
        type: 'text', // col.type
        class: 'bold-text',
        headerStyle: { width: '15%' },
      },
      {
        text: 'Client',
        value: 'client',
        sortable: false,
        type: 'client',
        headerStyle: { width: '15%' },
      },
      {
        text: 'Job Address',
        value: 'address',
        sortable: false,
        type: 'address',
        headerStyle: { width: '20%' },
      },
      {
        text: 'Created On',
        value: 'created_at_short',
        sortable: true,
        type: 'text',
        // type: 'formattedText',
        // formatFunction: 'formatDate',
        headerStyle: { width: '15%' },
      },
      {
        text: 'Created By',
        value: 'user_full_name',
        sortable: false,
        type: 'text',
        // type: 'formattedText',
        // formatFunction: 'formatDate',
        headerStyle: { width: '15%' },
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        type: 'actions',
        headerStyle: { width: '15%', position: 'sticky', right: 0 },
        className: 'actions-column',
        conditionalButtons: false,
        reorderableColumn: false,
        // frozen: true, // if set to true, this won't allow you to resize the column
        actions: [
          {
            text: 'EDIT',
            type: 'button',
            color: 'primary',
            emit: 'openEdit',
          },
          {
            text: 'DELETE',
            type: 'button',
            color: 'error',
            emit: 'softDelete',
          },
        ],
      },
    ],
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      projectsState: (state) => state.projectsState,
    }),
  },
  watch: {
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
  },
  created() {
    this.init();
    if (this.projectsState) {
      if (this.projectsState.pagination) {
        this.pagination = this.projectsState.pagination;
      }
    }
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    storeState(property, value) {
      const state = this.projectsState;
      state[property] = value;
      this.$store.commit('storeProjectsState', state);
    },

    init() {
      this.get();
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {},
        pagination: this.pagination,
        search: this.search,
      };

      this.headers = JSON.parse(JSON.stringify(this.defaultHeaders));
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        return;
      }
      if (!res || !res.data) {
        this.$bus.$emit('showError');
        this.items = [];
        this.total = 0;
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!item.id) return;

      const res = await this.$api.project.delete(item.id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    open() {
      this.$refs.projectForm.open();
    },

    openEdit(item) {
      this.$refs.projectForm.open(item.id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updatePagination(pagination) {
      if (
        pagination.page !== this.pagination.page
        || pagination.itemsPerPage !== this.pagination.itemsPerPage
      ) {
        this.pagination = pagination;
        this.storeState('pagination', pagination);
        this.itemsPerPage = this.pagination.itemsPerPage;
      }
    },

    syncHeadersOrder(reorderedHeaders) {
      const headers = [...this.headers];
      reorderedHeaders.forEach((header, index) => {
        const headerIndex = headers.findIndex((h) => h.text === header.text);
        if (headerIndex !== index) {
          const temp = headers[index];
          headers[index] = header;
          headers[headerIndex] = temp;
        }
      });
      this.headers = headers;
    },

    async resetHeaders() {
      this.headers = JSON.parse(JSON.stringify(this.defaultHeaders));
    },
  },
};
</script>
