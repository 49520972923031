<template>
  <v-row
    class="formRow"
    justify="center"
  >
    <v-dialog
      id="dialogId"
      v-model="dialog"
      max-width="600px"
      :persistent="formPersistent"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-model="formAlert"
              :type="formAlertType"
            >
              {{ formAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <inventory-class-select-element
                    v-model="inventoryClassId"
                    :is-required="true"
                    :is-add-all="false"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-if="inventoryClassId === inventoryClassPartsId"
                    v-model="departments"
                    label="Department"
                    :items="departmentsList"
                    item-text="name"
                    item-value="id"
                    multiple
                  >
                    <template #item="{ item }">
                      <v-chip
                        class="mt-1 mb-1"
                        small
                        text-color="white"
                        style="cursor: pointer;"
                        :color="item ? item.color : 'white'"
                      >
                        {{ item ? item.name : '' }}
                      </v-chip>
                    </template>
                    <template #selection="{ item }">
                      <v-chip
                        class="mt-1 mb-1"
                        small
                        text-color="white"
                        style="cursor: pointer;"
                        :color="item ? item.color : 'white'"
                      >
                        {{ item ? item.name : '' }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="name"
                    :rules="[(v) => !!v || 'Name is required']"
                    required
                  >
                    <template slot="label">
                      Name<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="businessRulesGetBoolean('Material Id')"
                  cols="6"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="material_id"
                    :rules="[(v) => !!v || 'Material ID is required']"
                    required
                  >
                    <template slot="label">
                      Material Id<RedAsterisk />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="businessRulesGetBoolean('Category')"
                  cols="6"
                  class="pb-0"
                >
                  <v-autocomplete

                    v-model="category"
                    label="Category"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    required
                  >
                    <template slot="label">
                      Category
                    </template>
                    <template #append-outer>
                      <v-btn
                        class="mrp-btn-add"
                        color="primary"
                        outlined
                        small
                        @click="addProperty('category')"
                      >
                        <v-icon small>
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="businessRulesGetBoolean('Manufacturer')"
                  cols="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="manufacturers"
                    label="Manufacturers"
                    :items="manufacturersList"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :multiple="true"
                    :rules="[(v) => !!v || 'Manufacturers is required']"
                    required
                  >
                    <template #append-outer>
                      <v-btn
                        class="mrp-btn-add"
                        color="primary"
                        outlined
                        small
                        @click="addProperty('manufacturer')"
                      >
                        <v-icon small>
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="colors"
                    label="Colors"
                    :items="colorsList"
                    :item-text="item => item.code ? item.code : item.name ? item.name: ''"
                    item-value="id"
                    :clearable="true"
                    :multiple="true"
                    :rules="[(v) => !!v || 'Colors is required']"
                    required
                  >
                    <template #append-outer>
                      <v-btn
                        class="mrp-btn-add"
                        color="primary"
                        outlined
                        small
                        @click="addProperty('color')"
                      >
                        <v-icon small>
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="businessRulesGetBoolean('Material Property')"
                  cols="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="materialProperties"
                    label="Material Properties"
                    :items="materialPropertiesList"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :multiple="true"
                    :rules="[(v) => !!v || 'Material Properties is required']"
                    required
                  >
                    <template #append-outer>
                      <v-btn
                        class="mrp-btn-add"
                        color="primary"
                        outlined
                        small
                        @click="addProperty('material property')"
                      >
                        <v-icon small>
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="uoms"
                    :items="uomsList"
                    item-text="name"
                    item-value="id"
                    :multiple="true"
                    :rules="[
                      (v) =>
                        !v || v.length > 0 || 'Units Of Measurement are required',
                      //!!v || 'Units Of Measurement are required',
                    ]"
                    required
                  >
                    <template #append-outer>
                      <v-btn
                        class="mrp-btn-add"
                        color="primary"
                        outlined
                        small
                        @click="addProperty('uom')"
                      >
                        <v-icon small>
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <template slot="label">
                      Units Of Measurement<RedAsterisk />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="lengths"
                    label="Lengths"
                    :items="lengthsList"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :multiple="true"
                    :rules="[(v) => !!v || 'Lengths is required']"
                    required
                  >
                    <template #append-outer>
                      <v-btn
                        class="mrp-btn-add"
                        color="primary"
                        outlined
                        small
                        @click="addProperty('length')"
                      >
                        <v-icon small>
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  class="pb-0"
                >
                  <v-text-field
                    v-model="weight"
                    suffix="kg/m"
                  >
                    <template slot="label">
                      Weight
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="inventoryClassId === inventoryClassPartsId"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="price"
                    prefix="$"
                    type="number"
                    min="0"
                  >
                    <template slot="label">
                      Price
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="businessRulesGetBoolean('Finishing')"
                  cols="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="finishings"
                    label="Finishings"
                    :items="finishingsList"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :multiple="true"
                    :rules="[(v) => !!v || 'Finishings is required']"
                    required
                  >
                    <template #append-outer>
                      <v-btn
                        class="mrp-btn-add"
                        color="primary"
                        outlined
                        small
                        @click="addProperty('finishing')"
                      >
                        <v-icon small>
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="photo"
                    accept="image/*"
                    item-text="photo"
                    item-value="photo"
                    label="Choose new Material Image"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <span
              v-if="isEdit"
              class="caption secondary--text pl-2"
            >Updated at {{ updatedDate }}</span>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import InventoryItemsApi from '../../api/InventoryItemsApi';
import ColorsApi from '../../api/ColorsApi';
import FinishingsApi from '../../api/FinishingsApi';
import ManufacturersApi from '../../api/ManufacturersApi';
import InventoryCategoriesApi from '../../api/InventoryCategoriesApi';
import UomsApi from '../../api/UomsApi';
import MaterialPropertiesApi from '../../api/MaterialPropertiesApi';
import LengthsApi from '../../api/LengthsApi';

export default {
  components: { },
  data: () => ({
    // Form
    dialog: false,
    dialogTitle: '',
    valid: false,
    formAlert: false,
    formAlertText: '',
    formAlertType: 'error',
    btnSubmitName: '',
    formPersistent: true,
    isEdit: false,
    inventoryClassPartsId: 11,
    // Inventory
    departmentsList: [],
    categories: [],
    manufacturersList: [],
    materialPropertiesList: [],
    lengthsList: [],
    uomsList: [],
    colorsList: [],
    finishingsList: [],
    // Inventory Item
    id: null,
    departments: [],
    category: null,
    name: null,
    manufacturers: [],
    uoms: [],
    materialProperties: [],
    lengths: [],
    colors: [],
    finishings: [],
    updatedDate: null,
    material_id: '',
    weight: null,
    price: null,
    photo: '',
    inventoryClassId: null,
  }),
  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
  },
  watch: {},
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      Promise.all([
        this.fetchDepartments(),
        this.fetchCategories(),
        this.fetchManufacturers(),
        this.fetchUoms(),
        this.fetchColors(),
        this.fetchFinishings(),
        this.fetchMaterialProperties(),
        this.fetchLengths(),
      ]);
    },
    openDialog(item = null) {
      this.clearForm();
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      // Fetching Colors and Catergories in case they were disabled
      this.$nextTick(async () => {
        if (item) {
          this.dialogTitle = `Edit Item #${item.code ? item.code : item.material_id}`;
          this.btnSubmitName = 'Update';
          this.isEdit = true;
          this.loadItemToForm(item);
        } else {
          this.dialogTitle = 'New Item';
          this.btnSubmitName = 'Create';
          this.isEdit = false;
        }
      });
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        const data = new FormData();

        if (this.id) {
          data.append('id', this.id);
        }

        if (this.inventoryClassId) {
          data.append('inventory_class_id', this.inventoryClassId);
        }

        if (this.departments) {
          for (var i = 0; i < this.departments.length; i++) {
            data.append('departments_ids[]', this.departments[i]);
          }
        }

        if (this.name) {
          data.append('name', this.name);
        }

        if (this.category) {
          data.append('inventory_category_id', this.category);
        }

        // if (this.manufacturers == '') {
        //   data.append('manufacturers_ids', 0);
        // }

        if (this.manufacturers) {
          for (var i = 0; i < this.manufacturers.length; i++) {
            data.append('manufacturers_ids[]', this.manufacturers[i]);
          }
        }

        if (this.uoms) {
          for (var i = 0; i < this.uoms.length; i++) {
            data.append('uoms_ids[]', this.uoms[i]);
          }
        }

        if (this.materialProperties) {
          for (var i = 0; i < this.materialProperties.length; i++) {
            data.append('material_properties_ids[]', this.materialProperties[i]);
          }
        }

        if (this.lengths) {
          for (var i = 0; i < this.lengths.length; i++) {
            data.append('lengths_ids[]', this.lengths[i]);
          }
        }

        if (this.colors) {
          for (var i = 0; i < this.colors.length; i++) {
            data.append('colors_ids[]', this.colors[i]);
          }
        }

        if (this.finishings) {
          for (var i = 0; i < this.finishings.length; i++) {
            data.append('finishings_ids[]', this.finishings[i]);
          }
        }

        if (this.photo) {
          data.append('photo', this.photo);
        }

        if (this.type) {
          data.append('type', this.type);
        }

        if (this.material_id) {
          data.append('material_id', this.material_id);
        }

        if (this.weight) {
          data.append('weight', this.weight);
        }

        if (this.price) {
          data.append('price', this.price);
        }

        data.append('created_by', this.$store.getters.user.id);

        const res = await InventoryItemsApi.storeFormData(data);
        if (res) {
          this.$emit('item-creation-success');
        } else {
          this.$emit('item-creation-fail');
        }
        this.dialog = false;
      }
    },
    async addProperty(prop) {
      const message = `Please enter new ${prop}`;
      let propVal = prompt(message);

      if (!propVal || propVal === '') {
        return false;
      }
      propVal = propVal.trim();

      let res = false;
      let exists = false;
      let errorText = 'Success!';
      let errorType = 'success';
      const data = { name: propVal };

      switch (prop) {
        case 'category':
          await this.fetchCategories();
          exists = this.categories.findIndex(
            (x) => x.name.toLowerCase() === propVal.toLowerCase(),
          );
          if (exists != -1) {
            errorText = 'Error! Category already exists.';
            errorType = 'error';
          } else {
            res = await InventoryCategoriesApi.store(data);
            if (res) {
              await this.fetchCategories();
              this.category = this.categories.find(
                (x) => x.name === propVal,
              ).id;
            }
          }
          break;
        case 'manufacturer':
          await this.fetchManufacturers();
          exists = this.manufacturers.findIndex(
            (x) => x.name.toLowerCase() === propVal.toLowerCase(),
          );
          if (exists != -1) {
            errorText = 'Error! Manufacturer already exists.';
            errorType = 'error';
          } else {
            res = await ManufacturersApi.store(data);
            if (res) {
              await this.fetchManufacturers();
              if (!this.manufacturers) {
                this.manufacturers = [];
              }
              this.manufacturers.push(
                this.manufacturersList.find((x) => x.name === propVal).id,
              );
            }
          }
          break;
        case 'uom':
          await this.fetchUoms();
          exists = this.uomsList.findIndex(
            (x) => x.name.toLowerCase() === propVal.toLowerCase(),
          );
          if (exists != -1) {
            errorText = 'Error! UOM already exists.';
            errorType = 'error';
          } else {
            res = await UomsApi.store(data);
            if (res) {
              await this.fetchUoms();
              if (!this.uoms) {
                this.uoms = [];
              }
              this.uoms.push(this.uomsList.find((x) => x.name === propVal).id);
            }
          }
          break;
        case 'color':
          await this.fetchColors();
          exists = this.colorsList.findIndex(
            (x) => x.code.toLowerCase() === propVal.toLowerCase(),
          );
          if (exists != -1) {
            errorText = 'Error! Color already exists.';
            errorType = 'error';
          } else {
            const colorData = { code: data.name };
            res = await ColorsApi.store(colorData);

            if (res) {
              await this.fetchColors();
              if (!this.colorsList) {
                this.colorsList = [];
              }
              this.colors.push(
                this.colorsList.find((x) => x.code === propVal).id,
              );
            }
          }
          break;
        case 'finishing':
          await this.fetchFinishings();
          exists = this.finishingsList.findIndex(
            (x) => x.name.toLowerCase() === propVal.toLowerCase(),
          );
          if (exists != -1) {
            errorText = 'Error! Finishing already exists.';
            errorType = 'error';
          } else {
            res = await FinishingsApi.store(data);
            if (res) {
              await this.fetchFinishings();
              if (!this.finishings) {
                this.finishings = [];
              }
              this.finishings.push(
                this.finishingsList.find((x) => x.name === propVal).id,
              );
            }
          }
          break;
        case 'material property':
          await this.fetchMaterialProperties();

          exists = this.materialPropertiesList.findIndex(
            (x) => x.name.toLowerCase() === propVal.toLowerCase(),
          );
          if (exists != -1) {
            errorText = 'Error! Material Property already exists.';
            errorType = 'error';
          } else {
            res = await MaterialPropertiesApi.store(data);
            if (res) {
              await this.fetchMaterialProperties();
              if (!this.materialProperties) {
                this.materialProperties = [];
              }
              this.materialProperties.push(
                this.materialPropertiesList.find((x) => x.name === propVal).id,
              );
            }
          }
          break;
        case 'length':
          await this.fetchLengths();
          exists = this.lengthsList.findIndex(
            (x) => x.name.toLowerCase() === propVal.toLowerCase(),
          );
          if (exists != -1) {
            errorText = 'Error! Length already exists.';
            errorType = 'error';
          } else {
            if (this.inventoryClassId) {
              data.inventory_class_ids = [this.inventoryClassId];
            }
            res = await LengthsApi.store(data);
            if (res) {
              await this.fetchLengths();
              if (!this.lengths) {
                this.lengths = [];
              }
              this.lengths.push(
                this.lengthsList.find((x) => x.name === propVal).id,
              );
            }
          }
          break;
        default:
          break;
      }

      this.showFormAlert(errorText, errorType);
    },
    showFormAlert(message, type) {
      this.formAlert = true;
      this.formAlertText = message;
      this.formAlertType = type;
      setTimeout(() => {
        this.formAlert = false;
      }, 3000);
    },
    async loadItemToForm(item) {
      console.log(item);
      await this.$refs.form.reset();
      if (!item) return;
      if (item.inventory_class_id) {
        this.inventoryClassId = item.inventory_class_id;
      }
      this.updatedDate = item.updated_at;
      this.id = item.id;
      this.category = item.category_id;
      this.departments = item.departments_ids ? item.departments_ids.split(',').map(Number) : [];
      this.name = item.name;
      this.photo = item.photo_name;
      this.photo_data = item.photo;
      this.mime_type = item.mime_type;
      this.material_id = item.material_id;
      this.weight = item.weight;
      this.uoms = item.uoms_ids ? item.uoms_ids.split(',').map(Number) : [];
      this.materialProperties = item.material_properties
        ? item.material_properties_ids.split(',').map(Number)
        : [];
      this.lengths = item.lengths
        ? item.lengths_ids.split(',').map(Number)
        : [];
      this.colors = item.colors ? item.colors_ids.split(',').map(Number) : [];
      this.finishings = item.finishings
        ? item.finishings_ids.split(',').map(Number)
        : [];
      this.manufacturers = item.manufacturers
        ? item.manufacturers_ids.split(',').map(Number)
        : [];
    },
    async clearForm() {
      if (this.$refs.form) await this.$refs.form.reset();
      this.id = null;
      this.category = null;
      this.manufacturer = null;
      this.name = null;
      this.uoms = null;
      this.materialProperty = '';
      this.length = '';
      this.color = null;
      this.finishing = null;
      this.photo = null;
      this.photo_name = null;
      this.photo_data = null;
      this.weight = null;
    },
    async fetchDepartments() {
      const departmentsList = await this.$api.department.getList();
      this.departmentsList = departmentsList.filter((department) => {
        if (department.is_deleted === 0) {
          return department;
        }
      });
    },
    async fetchCategories() {
      const categoryList = await InventoryCategoriesApi.getList();
      this.categories = categoryList.filter((cat) => {
        if (cat.deleted === 0 && cat.enabled === 1) {
          return cat;
        }
      });
    },
    async fetchManufacturers() {
      this.manufacturersList = await ManufacturersApi.getList();
    },
    async fetchUoms() {
      this.uomsList = await UomsApi.getList();
    },
    async fetchColors() {
      const submitModel = {
        filters: {
        },
      };

      this.colorsList = await this.$api.color.read(submitModel);
    },
    async fetchFinishings() {
      this.finishingsList = await FinishingsApi.getList();
    },
    async fetchMaterialProperties() {
      this.materialPropertiesList = await MaterialPropertiesApi.getList();
    },
    async fetchLengths() {
      this.lengthsList = await LengthsApi.getList();
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
  },
};
</script>

<style scoped>
.mrp-btn-add {
  min-width: 35px !important;
  padding: 0 !important;
}
</style>
