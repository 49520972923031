<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0">
        <v-tabs
          v-model="selectedTab"
        >
          <v-tabs v-model="selectedTab">
            <v-tab>
              Active
            </v-tab>
            <v-tab>
              Removed
            </v-tab>
          </v-tabs>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-select
          v-model="filterStatusId"
          :items="statuses"
          item-text="name"
          item-value="id"
          label="Status"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="2"
      >
        <v-menu
          ref="menuDate"
          v-model="dateMenu"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterDate"
              label="Date"
              readonly
              v-bind="attrs"
              append-icon="mdi-calendar"
              v-on="on"
              @change="$refs.menuDate.save(filterDate)"
            />
          </template>
          <v-date-picker
            v-model="filterDate"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterDate = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterUserId"
          label="User"
          :items="users"
          :item-text="(user) => `${user.first_name} ${user.last_name}`"
          item-value="id"
          clearable
        />
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="mt-4 pb-0 pr-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="openSingleGlassSlip"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Single Glass Slip
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="mt-4 pb-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="open"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Packing Slip
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            dense
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #[`item.slip_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.slip_number ? item.slip_number : '' }}
              </text-highlight>
            </template>
            <template #[`item.quantity`]="{ item }">
              <text-highlight :queries="search">
                {{ item.total_item_qty ? item.total_item_qty : '0' }}
              </text-highlight>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                small
                :color="item && item.status ? item.status.color : ''"
                text-color="white"
              >
                <text-highlight :queries="search">
                  {{ item && item.status ? item.status.name : '' }}
                </text-highlight>
              </v-chip>
            </template>
            <template #[`item.produced`]="{ item }">
              <text-highlight
                style="font-weight: 500;"
                :queries="search"
              >
                {{ item.produced_by_full_name ? item.produced_by_full_name : '' }}
              </text-highlight>
              <text-highlight
                :queries="search"
              >
                {{ item.produced_at ? item.produced_at : '' }}
              </text-highlight>
            </template>
            <template #[`item.shipped`]="{ item }">
              <text-highlight
                style="font-weight: 500;"
                :queries="search"
              >
                {{ item.shipped_by_full_name ? item.shipped_by_full_name : '' }}
              </text-highlight>
              <text-highlight :queries="search">
                {{ item.shipped_at ? item.shipped_at : '' }}
              </text-highlight>
            </template>
            <template #[`item.received`]="{ item }">
              <text-highlight
                style="font-weight: 500;"
                :queries="search"
              >
                {{ item.received_by_full_name ? item.received_by_full_name : '' }}
              </text-highlight>
              <text-highlight :queries="search">
                {{ item.received_at ? item.received_at : '' }}
              </text-highlight>
              <br>
              <text-highlight
                style="color: orange"
                :queries="search"
              >
                {{ item.location_name ? item.location_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.shipped_to_site`]="{ item }">
              <text-highlight
                style="font-weight: 500;"
                :queries="search"
              >
                {{ item.shipped_to_site_by_full_name ? item.shipped_to_site_by_full_name : '' }}
              </text-highlight>
              <text-highlight :queries="search">
                {{ item.shipped_to_site_at ? item.shipped_to_site_at : '' }}
              </text-highlight>
              <br>
              <text-highlight
                style="color: orange"
                :queries="search"
              >
                {{ item.project_name ? item.project_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.received_on_site`]="{ item }">
              <text-highlight
                style="font-weight: 500;"
                :queries="search"
              >
                {{ item.received_on_site_by_full_name ? item.received_on_site_by_full_name : '' }}
              </text-highlight>
              <text-highlight :queries="search">
                {{ item.received_on_site_at ? item.received_on_site_at : '' }}
              </text-highlight>
            </template>
            <template
              v-if="selectedTab === 0"
              #[`item.actions`]="{ item }"
            >
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="edit(item)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1 mr-1"
                color="success"
                outlined
                small
                @click="runWebhook(item)"
              >
                PDF
              </v-btn>
              <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mt-1 mb-1"
                    elevation="0"
                    fab
                    outlined
                    x-small
                    v-bind="attrs"
                    tile
                    color="primary"
                    style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                  class="pa-3"
                >
                  <v-list-item
                    v-if="isAdmin"
                    @click="softDelete(item)"
                  >
                    <v-list-item-title :style="`color: red; font-weight: 600;`">
                      <v-row align="center">
                        <v-col
                          cols="auto"
                          class="pr-0"
                          style="white-space: nowrap;"
                        />
                        <v-col class="pt-4">
                          DELETE
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item
                    v-else
                    @click="remove(item)"
                  >
                    <v-list-item-title :style="`color: red; font-weight: 600;`">
                      <v-row align="center">
                        <v-col
                          cols="auto"
                          class="pr-0"
                          style="white-space: nowrap;"
                        />
                        <v-col class="pt-4">
                          REMOVE
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <glass-packaging-slip-form
      ref="glassPackagingSlipForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GlassPackagingSlipForm from '@/components/forms/glasses/GlassPackagingSlipForm';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {
    GlassPackagingSlipForm,
  },
  props: {

  },
  data: () => ({
    items: [],
    statuses: [],
    users: [],
    footer: {
      'items-per-page-options': [20, 100, 500, 1000, -1],
    },
    loading: false,
    total: 0,
    pagination: {
      itemsPerPage: 20,
      page: 1,
    },
    headers: [
      { text: '#', value: 'slip_number' },
      { text: 'Qty', value: 'quantity', width: '2%' },
      {
        text: 'Status', value: 'status', align: 'center', width: '7%',
      },
      { text: 'Produced', value: 'produced' },
      { text: 'Shipped', value: 'shipped' },
      { text: 'Received', value: 'received' },
      { text: 'Shipped To Site', value: 'shipped_to_site' },
      { text: 'Received On Site', value: 'received_on_site' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],

    roles: [],
    userRoleIdAdmin: 3,

    dateMenu: false,
    filterStatusId: null,
    filterDate: null,
    filterUserId: null,
    glassPackagingSlipFormEditState: 1,
    selectedTab: 0,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
    ...mapGetters([

    ]),
    isAdmin() {
      return this.roles.includes(this.userRoleIdAdmin);
    },
  },
  watch: {
    search() {
      this.get();
    },
    filterStatusId() {
      this.get();
    },
    filterDate() {
      this.get();
    },
    filterUserId() {
      this.get();
    },
    pagination() {
      this.get();
    },
    selectedTab() {
      this.get();
    },
  },
  created() {
    this.roles = this.user.roles.map((x) => x.id);
    this.init();
  },
  methods: {
    init() {
      this.get();
      this.getStatuses();
      this.getUsers();
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {
          status_id: this.filterStatusId,
          date: this.filterDate,
          user_id: this.filterUserId,
          is_removed: this.selectedTab,
        },
        search: this.search,
        pagination: this.pagination,
      };
      const res = await this.$api.glassPackagingSlip.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data) {
        this.items = [];
        this.loading = false;
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;
    },

    open() {
      this.$refs.glassPackagingSlipForm.open();
    },

    edit(item) {
      this.$refs.glassPackagingSlipForm.open(item.id, this.glassPackagingSlipFormEditState);
    },

    openSingleGlassSlip() {
      this.$refs.glassPackagingSlipForm.open(null, null, true);
    },

    async remove(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record?',
      });
      if (!confirm) return;
      if (!item.id) return;
      this.$bus.$emit('loading');
      const res = await this.$api.glassPackagingSlip.get(`remove/${item.id}`);

      if (!res) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('loading');
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!item.id) return;
      this.$bus.$emit('loading');
      const res = await this.$api.glassPackagingSlip.delete(item.id);

      if (!res) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('loading');
      this.$bus.$emit('showSuccess');
      this.get();
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { this.pagination = pagination; }
    },

    success() {
      this.get();
    },

    async getStatuses() {
      this.statuses = [];
      const res = await this.$api.glassPoStatus.get();
      this.statuses = res;
    },

    async getUsers() {
      this.users = [];
      const res = await this.$api.user.get();
      this.users = res;
    },

    async runWebhook(item) {
      this.$bus.$emit('loading');
      const webhookModuleIdPackagingSlip = 41;
      const webhookData = {
        module_id: webhookModuleIdPackagingSlip,
        id: item.id,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.slip_number}_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

  },
};
</script>

<style scoped>
span
{
  font-size: 12px !important;
}
</style>
